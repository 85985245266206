import React from "react";
import colors from "../../constants/colors";
const styles = {
  signupButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 200,
    paddingLeft: 30,
    paddingRight: 30,
    height: 50,
    borderRadius: 5,
    backgroundColor: colors.orange,
    marginBottom: 50,
    fontWeight: "bold" as const,
    zIndex: 99,
    cursor: "pointer",
  },
  invertedSignupButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 200,
    paddingLeft: 30,
    paddingRight: 30,
    height: 50,
    borderRadius: 15,
    backgroundColor: colors.black,
    color: colors.orange,
    marginBottom: 50,
    fontWeight: "bold" as const,
    cursor: "pointer",
  },
  outlineSignupButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 200,
    paddingLeft: 30,
    paddingRight: 30,
    height: 50,
    borderRadius: 5,
    backgroundColor: "rgba(256, 256, 256, 0.05)",

    color: "rgba(256, 256, 256, 0.7)",
    fontWeight: "bold" as const,
    cursor: "pointer",
    fontSize: 15,
  },
};

export function CTA({ title, onClick }) {
  return (
    <div style={styles.signupButton} onClick={onClick}>
      <p style={{ color: "black" }}>{title}</p>
    </div>
  );
}

export function SmallCTA({ title, onClick }) {
  return (
    <div
      style={{ ...styles.signupButton, minWidth: 120, margin: 0 }}
      onClick={onClick}
    >
      <p>{title}</p>
    </div>
  );
}

export function SmallDarkCTA({ title, onClick, width }) {
  return (
    <div
      style={{
        ...styles.signupButton,
        minWidth: width,
        width: width,
        margin: 0,
      }}
      onClick={onClick}
    >
      <p style={{ color: "#000" }}>{title}</p>
    </div>
  );
}

export function InvertedCTA({ title }) {
  return (
    <div style={styles.invertedSignupButton}>
      <p>{title}</p>
    </div>
  );
}

export function OutlineCTA({ title, onClick }) {
  return (
    <div style={styles.outlineSignupButton} onClick={onClick}>
      <p>{title}</p>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import {
  PageTitle,
  PageSubtitleNoMargin,
  OrangeTitle,
} from "../../components/text";
import { useFirestoreConnect } from "react-redux-firebase";
import { useMe, usePackages } from "@avntspace/avnt-lib-firebase";
import { useParams } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import colors from "../../constants/colors";
import Checkout from "./components/checkout";
import logo from "../../assets/logo-high.png";
import LoginModal from "../../components/modals/login-modal";

export default function CreatorPaymentPage({ isMobile }) {
  const styles = {
    headerContainer: {
      display: "flex",
      flexDirection: "column" as const,

      paddingTop: isMobile ? 50 : 100,
      alignItems: "center",
      minHeight: "100vh",
    },
    detailsContainer: {
      display: "flex",
      flexDirection: isMobile ? ("column" as const) : ("row" as const),
      marginTop: 40,
      paddingBottom: 40,
      borderBottom: "2px solid #fff",
    },
    image: {
      width: 95,
      height: 95,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundImage: "url(" + logo + ")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "contain",
    },
    imageContainer: {
      width: 100,
      height: 100,
      borderColor: colors.orange,
      borderRadius: 8,
      marginRight: 40,
      borderWidth: 2,
      borderStyle: "solid",
    },
    priceRow: {
      width: isMobile ? "85vw" : 700,
      flexDirection: "row" as const,
      marginTop: 8,
      display: "flex",
      justifyContent: "space-between",
    },
  };
  let { packageId } = useParams<any>();
  const me = useMe();
  const plans = usePackages();
  useFirestoreConnect([{ collection: "packages" }]);
  const [modalShowing, setModalShowing] = useState(false);
  const [ready, setReady] = useState(false);

  let modal: any;

  const hideModal = () => {
    setModalShowing(false);
    modal.hide();
  };

  const showModal = () => {
    if (modal) {
      setModalShowing(true);
      modal.show();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 2000);
  });

  const plan =
    plans && plans.length > 0
      ? plans.find((item) => (item as any).id === packageId)
      : null;

  return plan ? (
    <div style={styles.headerContainer}>
      <div style={{ marginLeft: isMobile ? 0 : -80 }}>
        <PageTitle title={`${plan.name}  `} />
        <PageSubtitleNoMargin title={"Complete payment"} />
        <OrangeTitle title={me.username} />
      </div>

      <div style={styles.detailsContainer}>
        {isMobile ? (
          <div />
        ) : (
          <div style={styles.imageContainer}>
            <div style={styles.image} />
          </div>
        )}

        <div
          style={{
            fontSize: 15,
            maxWidth: "80vw",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {ReactHtmlParser(plan.description)}
        </div>
      </div>

      <div style={styles.priceRow}>
        <p>Subscription Term</p>
        <p>{plan.yearly ? "yearly" : "monthly"}</p>
      </div>
      <div style={styles.priceRow}>
        <p>Price</p>
        <p>{`$${plan.price}`}</p>
      </div>

      <Checkout plan={plan} isMobile={isMobile} />

      {ready ? (
        <LoginModal
          setModal={(ref) => {
            if (ref) {
              modal = ref;
              if (me && !me.username && !modalShowing) {
                showModal();
              }
            }
          }}
          hideModal={hideModal}
          isMobile={false}
          onModalHide={() => {
            if (me && !me.username) {
              showModal();
            }
          }}
        />
      ) : (
        <div />
      )}
    </div>
  ) : (
    <div style={styles.headerContainer}></div>
  );
}

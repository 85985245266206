import { useMe, usePackages } from "@avntspace/avnt-lib-firebase";
import React, { useState } from "react";
import { useFirestoreConnect } from "react-redux-firebase";
import TestimonialsGrid from "../../components/testimonials/testimonial-grid";
import { PageSubtitle, PageTitle } from "../../components/text";
import Toggle from "react-toggle";
import colors from "../../constants/colors";
import { CTA } from "../../components/buttons/buttons";
import FeaturedBrandsRow from "../../components/brands/featured-brands-row";
import HowItWorks from "../home-page/components/how-it-works";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { RiAccountPinCircleFill, RiShirtFill } from "react-icons/ri";
import PackageItem from "./components/package-item";
import { useHistory } from "react-router-dom";
import ReadonlyMap from "../../components/map/readonly-map";
import "react-toggle/style.css"; // for ES6 modules

export default function BecomeCreatorPage({ isMobile }) {
  const styles = {
    headerContainer: {
      display: "flex",
      flexDirection: "column" as const,

      marginTop: -70,
      alignItems: "center",
      minHeight: "100vh",
      maxWidth: "100vw",
    },
    orangeWorksContainer: {
      height: 200,
      marginTop: 40,
      display: "flex",
      flexDirection: "row" as const,
      marginLeft: 100,
      marginRight: 100,
      marginBottom: 60,
    },
    orangeWorks: {
      display: "flex",
      padding: 20,
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      borderWidth: 3,
      borderStyle: "solid",
      borderColor: colors.orange,
      borderRadius: 8,
      marginLeft: 10,
      textAlign: "center" as const,
      marginRight: 10,
    },
    gradient: {
      height: "70vh",

      background:
        "linear-gradient(0deg, rgba(14,17,17,1) 5%, rgba(14,17,17,0.9) 34%, rgba(14,17,17,0.3) 100%)",
      width: "100vw",
      marginTop: "-70vh",
      zIndex: 99,
      display: "flex",
      flexDirection: "column" as const,
      justifyContent: "flex-end",
    },
  };

  const me = useMe();
  const brandData = BRAND_DATA;

  const packages = usePackages();
  const history = useHistory();
  // const [showYearly, setShowYearly] = useState(false);

  useFirestoreConnect([
    {
      collection: "packages",
      where: ["archived", "==", false],
    },
  ]);

  const sortedPackages = () => {
    let pkgs = (packages || []) as any[];
    // .filter((item) => (showYearly ? item.yearly : !item.yearly));

    pkgs = pkgs.sort((a, b) => {
      if (a.ordering && b.ordering) {
        if (a.ordering > b.ordering) {
          return 1;
        }
        if (a.ordering < b.ordering) {
          return -1;
        }
      } else if (a.ordering) {
        return -1;
      } else if (b.ordering) {
        return 1;
      }

      return 0;
    });
    return pkgs;
  };
  return (
    <div style={styles.headerContainer}>
      {/* <ReadonlyMap brands={brandData} height={"70vh"} width={"100vw"} /> */}

      {/* <div style={styles.gradient}></div> */}

      <div
        style={{
          // marginTop: -130,
          marginTop: isMobile ? 150 : 200,
          zIndex: 100,
          marginBottom: 40,
          paddingLeft: isMobile ? "5vw" : "15vw",
          paddingRight: isMobile ? "5vw" : "15vw",
        }}
      >
        <p
          style={{
            fontWeight: "bold",
            color: colors.orange,
            fontSize: 20,
            textAlign: "center",
          }}
        >
          The AVNT Club
        </p>
        <div style={{ height: 20, width: 2 }} />
        {/* <PageSubtitle
          isMobile={isMobile}
          title="Welcome, you’ve been invited to be part of an exclusive club of the best next generation creatives on the fastest growing fashion and streetwear platform in the world."
        /> */}
        <p style={{ textAlign: "center" }}>
          Welcome, you’ve been invited to be part of an exclusive club of the
          best next generation creatives on the fastest growing fashion and
          streetwear platform in the world
        </p>
      </div>

      {/* <PageTitle isMobile={isMobile} title="Our Plans" /> */}
      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 30,
        }}
      >
        <span
          style={{
            width: isMobile ? 125 : 300,
            textAlign: isMobile ? "center" : "right",
            paddingRight: 8,
            opacity: showYearly ? 0.4 : 1,
          }}
        >
          Pay Monthly
        </span>
        <Toggle
          icons={false}
          className="yearly-toggle"
          id="yearly-status"
          checked={showYearly}
          aria-labelledby="yearly-label"
          onChange={(val) => setShowYearly(!showYearly)}
        />
        <span
          style={{
            width: isMobile ? 125 : 300,
            textAlign: isMobile ? "center" : "left",
            paddingLeft: 8,
            opacity: showYearly ? 1 : 0.4,
          }}
          id="yearly-label"
        >
          Pay Yearly (save over 25%)
        </span>
      </div> */}

      <div
        style={{
          minHeight: "80vh",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={
            // isMobile
            //   ?
            { display: "flex", flexDirection: "column" }
            // : {
            //     display: "flex",
            //     flexDirection: "row",
            //     flexWrap: "wrap",

            //     maxWidth: 1380,
            //   }
          }
        >
          {sortedPackages().map((item, index) => (
            <PackageItem package={item} index={index} isMobile={isMobile} />
          ))}
        </div>
      </div>

      <div
        style={{
          marginTop: 100,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PageTitle
          isMobile={isMobile}
          title="The platform for the next generation of creatives"
        />

        <PageSubtitle
          isMobile={isMobile}
          title="Trusted by hundreds of brands, designers, and creatives around the world"
        />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: "100vw",
          }}
        >
          <FeaturedBrandsRow textColor={colors.orange} />
        </div>
      </div>
    </div>
  );
}

export const BRAND_DATA = [
  { latitude: 41.73574681384387, longitude: -87.66296552260461 },
  { longitude: -111.888064, latitude: 40.759409 },
  { longitude: -84.405377, latitude: 33.836393 },
  { latitude: 39.952583, longitude: -75.165222 },
  { latitude: -20.728831, longitude: 139.499028 },
  { latitude: 34.083806276370076, longitude: -118.3566040080448 },
  { latitude: 34.039724, longitude: -118.251704 },
  { longitude: -73.202513, latitude: 40.85063 },
  { longitude: -111.96608, latitude: 41.227927 },
  { longitude: -95.39931, latitude: 29.790839 },
  { longitude: -0.143871, latitude: 51.527996 },
  { latitude: 33.92405362371758, longitude: -79.7360094261467 },
  { latitude: 37.78825, longitude: -122.4324 },
  { longitude: 19.451106333730166, latitude: 51.77076906906185 },
  { longitude: -111.933314, latitude: 33.416089 },
  { longitude: -79.079979, latitude: 40.009401 },
  { latitude: 39.154713, longitude: -86.531839 },
  { latitude: 39.452593, longitude: -74.727518 },
  { longitude: -118.208714, latitude: 33.976006 },
  { latitude: 5.554108, longitude: -0.169349 },
  { longitude: -118.251473, latitude: 34.149987 },
  { latitude: 29.22608305268619, longitude: -82.16465691165024 },
  { latitude: 46.84677111841634, longitude: -100.79237065200155 },
  { longitude: -84.403688, latitude: 33.745964 },
  { longitude: -117.99897, latitude: 33.660057 },
  { latitude: 34.024916, longitude: -118.403232 },
  { latitude: 47.943384, longitude: -122.203881 },
  { latitude: 35.157869, longitude: -90.041601 },
  { latitude: 59.927354, longitude: 30.355856 },
  { longitude: -86.792519, latitude: 36.169658 },
  { latitude: 40.714963, longitude: -74.011514 },
  { latitude: 37.638634, longitude: -120.994429 },
  { longitude: -81.09299, latitude: 32.07255 },
  { longitude: -75.974656, latitude: 40.958093 },
  { latitude: 45.523064, longitude: -123.676483 },
  { longitude: -73.139705, latitude: 40.799042 },
  { latitude: 43.1291426288263, longitude: -79.11564159448618 },
  { longitude: -118.356781, latitude: 33.912713 },
  { longitude: -87.623177, latitude: 41.8781 },
  { latitude: 33.989032, longitude: -118.300138 },
  { longitude: -118.269837, latitude: 33.780261 },
  { longitude: -76.920404, latitude: 38.630232 },
  { latitude: 47.751076, longitude: -120.740135 },
  { longitude: -93.763504, latitude: 32.523659 },
  { longitude: 145.035319, latitude: -37.877818 },
  { longitude: -73.9539278, latitude: 40.8189217 },
  { longitude: -76.747838, latitude: 38.442886 },
  { latitude: 40.681224, longitude: -73.940652 },
  { longitude: -80.00901376479354, latitude: 40.42981817408471 },
  { latitude: 34.2198492409892, longitude: -118.35000419555054 },
  { longitude: -0.118092, latitude: 51.509865 },
  { latitude: 32.836727, longitude: -97.103484 },
  { latitude: 33.717514, longitude: -116.219502 },
  { longitude: -73.946046, latitude: 40.591462 },
  { longitude: -73.560343, latitude: 45.490535 },
  { latitude: 40.848038, longitude: -73.89104 },
  { longitude: -117.853104, latitude: 33.787914 },
  { latitude: 43.801357, longitude: -91.239578 },
  { longitude: -118.48461962093428, latitude: 34.02014961375049 },
  { latitude: 35.34561694106836, longitude: -80.85269046941605 },
  { longitude: 23.722958, latitude: 37.989793 },
  { longitude: -73.997255, latitude: 40.731537 },
  { latitude: 38.58285454304129, longitude: -121.48480187616781 },
  { latitude: 42.33727713255398, longitude: -83.05060953736913 },
  { longitude: -117.406876, latitude: 34.58295 },
  { latitude: 39.704814, longitude: -105.013332 },
  { longitude: -76.889207, latitude: 38.757318 },
  { longitude: -115.100894, latitude: 36.099718 },
  { longitude: -75.15232657300277, latitude: 39.97705890629949 },
  { longitude: -118.4912, latitude: 34.0195 },
  { longitude: -124.08279877566517, latitude: 40.86505509559187 },
  { longitude: -76.99855, latitude: 38.888829 },
  { latitude: 42.734499090565166, longitude: -84.56320426428901 },
  { latitude: 40.75591149427728, longitude: -111.87782543999512 },
  { longitude: -73.955935, latitude: 40.707902 },
  { longitude: -122.624366, latitude: 45.468127 },
  { latitude: 32.70162650368863, longitude: -117.09888627877096 },
  { latitude: 39.762888, longitude: -86.158292 },
  { longitude: -82.574821, latitude: 27.498928 },
  { latitude: 33.654481, longitude: -83.718204 },
  { longitude: -118.315649, latitude: 34.013779 },
  { longitude: -80.86162216372591, latitude: 35.177064974341086 },
  { longitude: -105.40620920261146, latitude: 40.19460913033277 },
  { latitude: 32.704812, longitude: -117.127421 },
  { latitude: 33.502007, longitude: -117.140643 },
  { longitude: -118.259228, latitude: 34.07922 },
  { longitude: -84.559979, latitude: 42.738579 },
  { longitude: -117.619214, latitude: 33.420429 },
  { longitude: -123.09045407070973, latitude: 49.25896775047305 },
  { longitude: -75.695742, latitude: 45.419972 },
  { latitude: 37.337815415546956, longitude: -121.8860863090694 },
  { longitude: -78.43817304582073, latitude: 35.81927347364397 },
  { latitude: 33.979277, longitude: -118.269919 },
  { latitude: 40.735657, longitude: -74.172363 },
  { longitude: -117.380835, latitude: 33.19465 },
  { latitude: 39.10123246332674, longitude: -94.58209790754847 },
  { latitude: 39.294437, longitude: -76.615352 },
  { latitude: 33.515688, longitude: -86.807076 },
  { longitude: -118.551328, latitude: 34.206883 },
  { longitude: -117.8396702050657, latitude: 33.753175003448455 },
  { longitude: -118.03584315337379, latitude: 33.76942645627407 },
  { longitude: -74.014516, latitude: 40.70927 },
  { latitude: 36.852501, longitude: -121.401665 },
  { latitude: 33.94040320118806, longitude: -118.12706417955374 },
  { latitude: 55.815624, longitude: 37.583254 },
  { longitude: -117.302399, latitude: 34.115784 },
  { latitude: 36.82523, longitude: -119.702919 },
  { longitude: -88.27679817691234, latitude: 42.095685088932264 },
  { latitude: 48.873055, longitude: 2.297181 },
  { longitude: -83.027198, latitude: 42.354494 },
  { latitude: 34.052235, longitude: -118.5 },
  { latitude: 36.114647, longitude: -115.172813 },
  { longitude: -73.97926, latitude: 40.739286 },
  { longitude: -118.342141, latitude: 33.998065 },
  { longitude: -75.20698130172329, latitude: 39.95384238504613 },
  { longitude: 82.938013, latitude: 55.013678 },
  { longitude: -118.17355890810373, latitude: 34.040288612268924 },
  { longitude: -72.8952, latitude: 41.38809 },
  { longitude: -80.250299, latitude: 25.73147 },
  { longitude: -84.38633, latitude: 33.753746 },
  { longitude: -77.4542260573428, latitude: 37.544503951076194 },
  { latitude: 38.625321405612155, longitude: -90.19925939850339 },
  { longitude: -0.080897, latitude: 51.512543 },
  { latitude: 41.93087584553742, longitude: -74.00730397582743 },
  { longitude: -118.391513, latitude: 34.193797 },
  { longitude: -91.15187, latitude: 30.454818 },
  { longitude: -87.641232, latitude: 41.878079 },
  { latitude: 44.974759, longitude: -93.27037 },
  { longitude: -71.10633671440588, latitude: 41.89138649550515 },
  { latitude: 45.50952476760964, longitude: -73.60626882726477 },
  { longitude: -118.00916, latitude: 33.900633 },
  { longitude: -118.229781, latitude: 34.033252 },
  { latitude: 47.616551, longitude: -122.319359 },
  { latitude: 40.689548958567535, longitude: -73.94019907450465 },
  { longitude: -87.6235, latitude: 41.8781 },
  { longitude: -118.218065, latitude: 34.083418 },
  { longitude: -116.496917, latitude: 33.958695 },
  { latitude: 40.521893, longitude: -111.939102 },
  { latitude: 34.0336, longitude: -118.377198 },
  { longitude: -118.0488329599024, latitude: 34.102147281650204 },
  { latitude: 40.74578496251132, longitude: -74.24646647447193 },
  { latitude: 18.410153, longitude: -66.087142 },
  { longitude: -121.28408670030863, latitude: 37.95360691339354 },
  { latitude: 42.352172, longitude: -71.070333 },
  { longitude: -118.40804812371455, latitude: 34.08607686441919 },
  { longitude: -87.6341641995177, latitude: 41.88253041727755 },
  { latitude: 21.295264, longitude: -157.861338 },
  { latitude: 34.149247, longitude: -118.4391 },
  { longitude: -117.135366, latitude: 32.703474 },
  { longitude: -117.423462, latitude: 47.653944 },
  { longitude: -87.72262, latitude: 41.778769 },
  { latitude: 41.771069894757154, longitude: -87.61958723938785 },
  { longitude: -123.12042909653067, latitude: 49.27624337033365 },
  { latitude: 39.744671034362604, longitude: -75.54861803701766 },
  { latitude: 43.801434, longitude: -91.247299 },
  { longitude: -97.742377, latitude: 30.27139 },
  { longitude: -83.722291, latitude: 32.455447 },
  { longitude: -83.362825, latitude: 33.948931 },
  { longitude: 114.05318, latitude: 22.538325 },
  { longitude: -76.807838, latitude: 39.003719 },
  { longitude: -73.36361322138852, latitude: 40.755470748979114 },
  { latitude: 45.796981, longitude: 15.713826 },
  { latitude: 39.045753, longitude: -76.68 },
  { latitude: 33.75249740737935, longitude: -84.38602038206307 },
  { longitude: -118.541986, latitude: 34.199318 },
  { longitude: -118.19336365035984, latitude: 34.1093188384912 },
  { latitude: 52.372259, longitude: 4.880941 },
  { latitude: 33.9676953477939, longitude: -118.07763742264466 },
  { latitude: 37.78034261963889, longitude: -122.42085272444017 },
  { latitude: 37.548738, longitude: -77.40624 },
  { latitude: 47.622203, longitude: -122.166788 },
  { latitude: 39.941607, longitude: -75.215921 },
  { latitude: 29.749907, longitude: -95.358421 },
  { latitude: 33.74528417349169, longitude: -84.38971313780286 },
  { latitude: 35.74587, longitude: -89.530042 },
  { longitude: -2.085081, latitude: 57.152914 },
  { latitude: 34.08312416361348, longitude: -118.21856605363112 },
  { latitude: 31.185679, longitude: -94.782332 },
  { longitude: -147.711059, latitude: 64.842061 },
  { longitude: -83.638247, latitude: 32.843478 },
  { latitude: 41.878212585322764, longitude: -87.61814404708105 },
  { longitude: -84.392818, latitude: 33.838841 },
  { latitude: 41.88050542079049, longitude: -87.63906553251348 },
  { latitude: 33.53260903188408, longitude: -84.35595276412126 },
  { longitude: -80.830486, latitude: 35.225395 },
  { latitude: 34.053902, longitude: -118.245393 },
  { longitude: -76.46075734177278, latitude: 42.99393489431102 },
  { latitude: 42.254924770132845, longitude: -71.79039409925244 },
  { longitude: -122.4163817292655, latitude: 37.77368228012837 },
  { latitude: -37.840935, longitude: 144.946457 },
  { latitude: 43.669171, longitude: -70.247811 },
  { longitude: -85.52872129730768, latitude: 42.934344083699564 },
  { longitude: -81.66396561355428, latitude: 30.327779493495036 },
  { latitude: 32.899627, longitude: -96.783336 },
  { latitude: 34.096768, longitude: -118.377168 },
  { latitude: 30.217851070941766, longitude: -92.01636205293237 },
  { longitude: -118.215078, latitude: 33.899005 },
  { longitude: -113.490159, latitude: 53.550116 },
  { longitude: -77.31036442796034, latitude: 38.643947893051354 },
  { longitude: -86.8120566082999, latitude: 33.52118582812352 },
  { longitude: -0.08686, latitude: 51.51157 },
  { latitude: 41.619549, longitude: -93.598022 },
  { latitude: 38.572693, longitude: -121.496852 },
  { longitude: -77.23572852668518, latitude: 39.043529346584556 },
  { latitude: 43.229943, longitude: -86.251681 },
  { longitude: -70.93318135982256, latitude: 42.42911672979817 },
  { longitude: -73.98513, latitude: 40.758896 },
  { latitude: 40.60276357075046, longitude: -75.46375076709474 },
  { latitude: 33.24049834588675, longitude: -111.64650900545065 },
  { longitude: -84.348042, latitude: 33.73709 },
  { longitude: 100.523186, latitude: 13.736717 },
  { latitude: 38.854897, longitude: -77.469075 },
  { longitude: -73.70991165238064, latitude: 40.7081387934267 },
  { latitude: 29.70487848328606, longitude: -95.81123500404925 },
  { longitude: -87.6245, latitude: 41.881832 },
  { longitude: -96.808325, latitude: 32.79577 },
  { latitude: 36.833668473086234, longitude: -76.09586243577438 },
  { longitude: -76.906, latitude: 38.615913 },
  { longitude: -75.147216, latitude: 39.983261 },
  { latitude: 40.420681, longitude: -79.834612 },
  { longitude: -74.00307213898914, latitude: 40.726421619035 },
  { latitude: 36.070912, longitude: -79.787544 },
  { latitude: 41.45226, longitude: -74.422052 },
  { longitude: -80.279943, latitude: 26.270186 },
  { latitude: 45.931784, longitude: -118.384852 },
  { latitude: 42.139811, longitude: -71.247728 },
  { longitude: 125.752338, latitude: 39.030267 },
  { longitude: 99.884534, latitude: 34.894355 },
  { latitude: 38.44575279021853, longitude: -78.86917988798834 },
  { latitude: 33.74554229513028, longitude: -84.45536612845206 },
  { longitude: -118.321883, latitude: 34.172223 },
  { latitude: 39.158216, longitude: -75.52717 },
  { latitude: 35.778076, longitude: -78.632412 },
  { latitude: 40.576481, longitude: -74.228204 },
  { longitude: -84.387, latitude: 33.753746 },
  { latitude: 34.07281356313339, longitude: -118.40629617614759 },
  { longitude: -73.98548755253239, latitude: 40.662416225912025 },
  { latitude: 41.509741, longitude: -81.680717 },
  { longitude: -118.250462, latitude: 34.02961 },
  { latitude: 35.22498834726395, longitude: -80.84160021392582 },
  { longitude: 139.839478, latitude: 35.652832 },
  { latitude: 40.267071, longitude: -74.539138 },
  { longitude: -122.406417, latitude: 37.785834 },
  { latitude: 45.474406, longitude: 9.188562 },
  { longitude: -77.5636, latitude: 39.1157 },
  { longitude: -73.996667, latitude: 40.720461 },
  { longitude: -80.31676957169469, latitude: 26.16298775056376 },
  { latitude: 26.29877, longitude: -98.165652 },
  { latitude: 33.445581, longitude: -94.051815 },
  { latitude: 33.951429382405976, longitude: -83.98775533832116 },
  { longitude: -157.83300497910489, latitude: 21.30578202785686 },
  { longitude: -117.440471, latitude: 47.666098 },
  { latitude: 39.555982, longitude: -77.99407 },
  { latitude: 43.497302, longitude: -121.9296293 },
  { longitude: -87.632711, latitude: 41.834738 },
  { latitude: 34.032315, longitude: -118.253924 },
  { longitude: -117.57787924770795, latitude: 33.86935489584065 },
  { longitude: -84.4046867199673, latitude: 33.79787394479992 },
  { latitude: 34.090974, longitude: -118.417801 },
  { latitude: 40.650002, longitude: -73.93 },
  { latitude: 30.183515487451466, longitude: -97.84977911129803 },
  { latitude: 40.686455, longitude: -73.935801 },
  { longitude: -73.988095, latitude: 40.717056 },
  { latitude: 59.935206, longitude: 30.373631 },
  { longitude: -84.37906011978895, latitude: 33.82640704479521 },
  { latitude: 34.021461, longitude: -118.394558 },
  { longitude: -111.943698, latitude: 41.20258 },
  { longitude: -77.610924, latitude: 43.16103 },
  { longitude: -84.39079, latitude: 33.75639 },
  { longitude: -92.43143277579088, latitude: 44.00569673672757 },
  { longitude: -111.869693, latitude: 40.748201 },
  { latitude: 44.961024, longitude: -93.275247 },
  { latitude: 41.18639, longitude: -73.195557 },
  { latitude: 52.521415, longitude: 13.437569 },
  { latitude: 40.673833045381066, longitude: -73.9928101054131 },
  { latitude: 37.210475, longitude: -93.296532 },
  { latitude: 45.523064, longitude: -122.676483 },
  { longitude: -96.8037442709321, latitude: 32.776604621726285 },
  { latitude: 48.863055, longitude: 2.338361 },
  { longitude: -76.629618, latitude: 39.30116 },
  { longitude: -118.22394703768936, latitude: 33.89430493822419 },
  { latitude: 40.724045, longitude: -73.982444 },
  { latitude: 40.734107, longitude: -73.792274 },
  { latitude: 40.40173977315531, longitude: -74.13328858406115 },
  { latitude: 25.772962, longitude: -80.188952 },
  { latitude: 38.95826285134724, longitude: -77.34135663791203 },
  { longitude: -96.71731266840115, latitude: 32.90964247219204 },
  { latitude: 34.04962671016078, longitude: -118.24321525893801 },
  { latitude: 26.202638, longitude: -98.32148 },
  { longitude: -84.51473099060514, latitude: 39.10248104948999 },
  { latitude: 41.073243, longitude: -85.141699 },
  { latitude: 45.520699, longitude: -122.679974 },
  { latitude: 38.880463, longitude: -77.102897 },
  { latitude: 30.25809101739754, longitude: -97.74137547103673 },
  { longitude: -118.256949, latitude: 34.036812 },
  { longitude: -86.528263, latitude: 39.167311 },
  { longitude: -118.249845, latitude: 34.037728 },
  { longitude: -80.1591140604913, latitude: 26.011172854709404 },
  { longitude: -86.66748332571821, latitude: 36.47678975466463 },
  { longitude: -75.1050241, latitude: 39.9984762 },
  { longitude: -86.15465272377665, latitude: 39.767289202195585 },
  { longitude: -80.081203, latitude: 26.373835 },
  { longitude: 37.632887, latitude: 55.753538 },
  { longitude: -118.24266080448997, latitude: 33.8532952588194 },
  { longitude: -122.45398721511117, latitude: 48.762969826652196 },
  { latitude: 44.756867, longitude: -69.717182 },
  { longitude: 9.7328817, latitude: 47.431233 },
  { latitude: 52.485408, longitude: -1.886885 },
  { latitude: 37.747582563984274, longitude: -122.47541461302576 },
  { latitude: -80.62548470909324, longitude: 20.942609966321346 },
  { latitude: 25.761681, longitude: -80.191788 },
  { latitude: 43.4195, longitude: -83.9508 },
  { latitude: 34.193830832442416, longitude: -118.39153853722067 },
  { latitude: 33.20043395655836, longitude: -117.35017175110028 },
  { latitude: 37.765246, longitude: -122.425732 },
  { longitude: -74.783406, latitude: 39.999685 },
  { longitude: -104.99072381614359, latitude: 39.73641994390427 },
  { latitude: 52.238778, longitude: -0.904183 },
  { longitude: -96.30361388121607, latitude: 30.69058181515663 },
  { longitude: -80.849357, latitude: 35.263546 },
  { latitude: 35.313516, longitude: -80.781548 },
  { longitude: -76.86903147309597, latitude: 39.104113420310114 },
  { longitude: -118.451891, latitude: 34.176729 },
  { longitude: -71.42716, latitude: 41.811665 },
  { longitude: 37.606362847628574, latitude: 55.76394743949195 },
  { latitude: 33.7292803541474, longitude: -84.155251228675 },
  { latitude: 39.06469548817711, longitude: -77.09296355270251 },
  { longitude: -118.31608794629575, latitude: 33.765041206431455 },
  { longitude: -122.8097, latitude: 45.401535 },
  { latitude: 42.332539, longitude: -83.050344 },
  { latitude: 21.294198, longitude: -157.704302 },
  { longitude: -95.458093, latitude: 30.3148 },
  { longitude: -118.2385914322942, latitude: 34.05451564323005 },
  { latitude: 36.605164, longitude: -120.189784 },
  { longitude: -118.266851, latitude: 34.043895 },
  { latitude: 33.787914, longitude: -117.853104 },
  { latitude: 35.635895, longitude: -120.660175 },
  { latitude: 40.75030056460024, longitude: -73.9807718365494 },
  { latitude: 40.725429, longitude: -111.861166 },
  { latitude: 26.134044, longitude: -80.122543 },
  { longitude: 47.992824, latitude: 29.387082 },
  { longitude: -85, latitude: 33.753746 },
  { latitude: 40.64031, longitude: -73.983164 },
  { latitude: 43.63819911900329, longitude: -79.39790733550073 },
  { longitude: -74.304003, latitude: 40.525023 },
  { longitude: -93.9, latitude: 41.619549 },
  { longitude: -80.23648137995853, latitude: 26.655070550336127 },
  { longitude: 77.221068, latitude: 28.631047 },
  { longitude: 144.9632352102635, latitude: -37.81644719216485 },
  { latitude: 42.737319, longitude: -84.571627 },
  { latitude: 38.928361, longitude: -77.028458 },
  { longitude: -84.97494, latitude: 32.463627 },
  { longitude: -84.35070804398305, latitude: 33.76572143907793 },
  { latitude: 34.056458, longitude: -118.247131 },
  { latitude: 41.854073, longitude: -87.631958 },
  { longitude: -118.158083, latitude: 33.891143 },
  { latitude: 32.699053, longitude: -96.779635 },
  { latitude: 26.162160609267787, longitude: -80.2860430656549 },
  { latitude: 45.798197601272655, longitude: 15.723597684110345 },
  { longitude: -118.4312051638446, latitude: 34.148022728539566 },
  { longitude: -73.9007639492391, latitude: 40.67457759870339 },
  { latitude: -6.170949687711057, longitude: 106.83350307140488 },
  { latitude: 39.685868, longitude: -104.979446 },
  { longitude: -81.86960363560014, latitude: 41.29695422837875 },
  { longitude: -118.08113675265852, latitude: 33.985546693694275 },
  { longitude: -113.24997834340056, latitude: 53.53007716679631 },
  { latitude: 39.941571, longitude: -75.149324 },
  { latitude: 43.666613848455654, longitude: -79.41841608262297 },
  { latitude: 36.156893, longitude: -95.969536 },
  { latitude: 45.520624, longitude: -122.679188 },
  { longitude: -75.15695832110138, latitude: 39.982816378482624 },
  { longitude: -115.20611, latitude: 36.188254 },
  { longitude: 115.860446, latitude: -31.947607 },
  { latitude: 33.42225101180821, longitude: -111.54181463495803 },
  { latitude: 40.7741119749278, longitude: -73.94537691027176 },
  { longitude: -75.1051145, latitude: 39.9983487 },
  { latitude: 36.100007885330186, longitude: -115.10104418972973 },
  { longitude: -96.761888, latitude: 32.821145 },
  { latitude: 43.740174, longitude: -70.555816 },
  { latitude: 34.09389062992826, longitude: -118.33476121694905 },
  { longitude: -71.083573, latitude: 42.348881 },
  { longitude: -80.13266, latitude: 26.140742 },
  { latitude: 34.152708376308915, longitude: -118.6467588280054 },
  { longitude: -117.671058, latitude: 33.501822 },
  { latitude: 33.819998, longitude: -84.415992 },
  { latitude: 30.271519, longitude: -97.742987 },
  { latitude: 34.09943161549601, longitude: -117.43133057679292 },
  { longitude: -118.366098, latitude: 34.114609 },
  { latitude: 36.97193286464272, longitude: -122.04427169870925 },
  { latitude: 39.177784, longitude: -86.530556 },
  { longitude: -84.367416, latitude: 33.762181 },
  { latitude: 39.96684754697282, longitude: -83.0043665411786 },
  { longitude: -95.5, latitude: 29.749907 },
  { longitude: -73.997609, latitude: 40.711423 },
  { latitude: 34.04761941498629, longitude: -117.4868546811639 },
  { longitude: -85.586011, latitude: 42.289232 },
  { latitude: 32.792962, longitude: -96.785641 },
  { longitude: -117.1294939084287, latitude: 32.701188816478556 },
  { longitude: -72.681239, latitude: 41.777519 },
  { latitude: 34.23616470008074, longitude: -119.19094235411232 },
  { latitude: 42.047518, longitude: -87.677934 },
  { latitude: 33.902728025177524, longitude: -117.84080549295447 },
  { latitude: 41.37998085766577, longitude: 2.157580567276472 },
  { longitude: -118.157601, latitude: 33.763122 },
  { latitude: 44.17563367532194, longitude: -84.29691494042626 },
  { longitude: -97.475897, latitude: 31.05134 },
  { longitude: -77.44693549308904, latitude: 38.33167209073845 },
  { longitude: -0.134954, latitude: 51.511786 },
  { longitude: 12.521011926128555, latitude: 41.86162591895085 },
  { latitude: 40.650002, longitude: -73.949997 },
  { latitude: 39.934121418452776, longitude: -75.17450839871341 },
  { latitude: 34.035719, longitude: -118.261004 },
  { latitude: 29.689722, longitude: -95.287211 },
  { longitude: -121.870961, latitude: 37.325401 },
  { longitude: -77.420075, latitude: 37.532427 },
  { longitude: -122.335167, latitude: 47.608013 },
  { latitude: 34.19293, longitude: -79.76003 },
  { latitude: 33.652677, longitude: -117.973173 },
  { longitude: -117.042879, latitude: 34.023882 },
  { longitude: -118.4, latitude: 34.052235 },
  { latitude: 34.004791610122275, longitude: -117.73269568982047 },
  { longitude: -82.96194658912172, latitude: 42.481308706394614 },
  { longitude: -118.125678, latitude: 34.164559 },
  { latitude: 40.731101, longitude: -73.952496 },
  { latitude: 37.596751, longitude: -122.056948 },
  { longitude: 144.741247, latitude: -37.912309 },
  { latitude: 42.49397142897378, longitude: -71.11281543385758 },
  { latitude: 8.983876, longitude: -79.51873 },
  { latitude: 40.639529381473146, longitude: -73.95500755181556 },
  { longitude: -149.9233653139191, latitude: 61.215552031162645 },
  { longitude: -157.745261, latitude: 21.401358 },
  { longitude: -118.47994749231529, latitude: 34.03784321487111 },
  { longitude: -75.2402817, latitude: 39.9808535 },
  { longitude: -118.248272, latitude: 34.030419 },
  { latitude: 34.01928267241893, longitude: -118.1772291066683 },
  { latitude: 33.58261, longitude: -112.013208 },
  { latitude: 41.05128841164438, longitude: -73.54267198458219 },
  { latitude: 32.73935, longitude: -96.826512 },
  { latitude: 42.312304, longitude: -86.115179 },
  { latitude: 31.79953409898448, longitude: -106.29659060958735 },
  { longitude: -118.163169, latitude: 34.026419 },
  { latitude: 37.962657, longitude: -121.292543 },
  { latitude: 29.749907, longitude: -95.7 },
  { longitude: -74.019683, latitude: 40.774953 },
  { longitude: -114.10426216361469, latitude: 50.89910869190471 },
  { longitude: -73.99091661895832, latitude: 40.73515668291098 },
  { latitude: 34.011877, longitude: -118.354961 },
  { latitude: 34.107204, longitude: -117.806544 },
  { longitude: -82.78062404805024, latitude: 28.005443749728435 },
  { latitude: 33.888438, longitude: -84.508049 },
  { longitude: -117.05361301916118, latitude: 32.96565338269046 },
  { latitude: 36.15983572293593, longitude: -86.77833985851402 },
  { longitude: -93.24098, latitude: 45.00169 },
  { latitude: 42.983763433544375, longitude: -81.25049536725822 },
  { longitude: -115.2155349076753, latitude: 36.01507394187197 },
  { latitude: 32.800628, longitude: -96.765427 },
  { latitude: 49.895077, longitude: -97.138451 },
  { latitude: 40.75650773311939, longitude: -73.99147982700363 },
  { longitude: -66.164513, latitude: 18.369874 },
  { longitude: -4.269846963038922, latitude: 55.881818988375585 },
  { latitude: 36.321908, longitude: -119.335951 },
  { longitude: -74.65747640480485, latitude: 39.41631179171745 },
  { latitude: 41.938513, longitude: -87.776358 },
  { longitude: -74.522562712828, latitude: 40.27069570464987 },
  { latitude: 42.518241, longitude: -92.454727 },
  { latitude: 33.886485560282665, longitude: -84.18294151890737 },
  { latitude: 40.719217, longitude: -73.991258 },
  { latitude: 36.827775157975815, longitude: -76.47764162202503 },
  { latitude: 41.999795598497606, longitude: -87.657834051879 },
  { latitude: 34.122991, longitude: -118.171613 },
  { longitude: -75.37065, latitude: 40.609657 },
  { longitude: -122.75791386648565, latitude: 45.31082021624252 },
  { longitude: -73.94126864348678, latitude: 40.64999593245937 },
  { latitude: 44.7405494, longitude: -63.6508343 },
  { latitude: 33.978782, longitude: -118.218954 },
  { latitude: 37.978787, longitude: -121.293013 },
  { latitude: 33.735237, longitude: -84.338778 },
  { longitude: -112.073762, latitude: 33.446363 },
  { latitude: 40.07434308428678, longitude: -74.85569377002467 },
  { latitude: 40.56939555877462, longitude: -122.34314208850591 },
  { latitude: 41.89947368201547, longitude: -87.67698232583497 },
  { latitude: 33.361025, longitude: -111.939462 },
  { longitude: -86.809493, latitude: 33.53081 },
  { longitude: -66.07526587378798, latitude: 18.45572446487894 },
  { latitude: 40.541632, longitude: -74.363105 },
  { longitude: -117.16149833807944, latitude: 33.1414286870695 },
  { latitude: 33.913095, longitude: -118.350173 },
  { latitude: 34.21690836335033, longitude: -118.4106961453702 },
  { longitude: 151.208703, latitude: -33.8917 },
  { latitude: 47.666511, longitude: -117.249806 },
  { longitude: 145.011823, latitude: -37.764102 },
  { latitude: 40.865843, longitude: -124.086923 },
  { longitude: -80.821917, latitude: 35.215717 },
  { latitude: 42.458932, longitude: -71.00681 },
  { longitude: 174.073405, latitude: -41.355198 },
  { longitude: 126.977284, latitude: 37.562883 },
  { longitude: -78.901342, latitude: 42.951108 },
  { longitude: -73.9079991376265, latitude: 40.67435171504394 },
  { longitude: -73.888008, latitude: 40.855358 },
  { longitude: -96.782957, latitude: 32.782046 },
];

import React, { useEffect, useState } from "react";
import colors from "../../../constants/colors";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Package, useMe } from "@avntspace/avnt-lib-firebase";
import { useFirestore } from "react-redux-firebase";
import StripeService from "../../../services/stripeService";
import { DotLoader } from "react-spinners";

const STRIPE_OPTIONS = {
  style: {
    base: {
      fontSize: "18px",

      color: colors.white,
      "::placeholder": {
        color: "rgba(256, 256, 256, 0.3)",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};

export default function StripeCheckout({
  plan,
  createBrand,
  loading,
  setLoading,
  isMobile,
}: {
  plan: Package;
  createBrand: any;
  loading: boolean;
  setLoading: any;
  isMobile: boolean;
}) {
  const styles = {
    subscribeButton: {
      width: 300,
      height: 45,
      borderRadius: 4,
      marginBottom: 12,
      backgroundColor: colors.orange,
      border: "none",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      flexDirection: "column" as const,
      marginTop: 12,
      marginBottom: 12,
    },
    cardContainer: {
      borderBottom: "2px solid rgba(256, 256, 256, 0.3)",
      padding: 12,
      width: isMobile ? "90vw" : 500,
    },
  };

  const [customer, setCustomer] = useState(null);
  const [errMessage, setErrMessage] = useState("");

  const stripe = useStripe();
  const elements = useElements();
  const me = useMe();
  const firestore = useFirestore();

  useEffect(() => {
    StripeService.fetchStripeCustomer(me.email).then((res) => {
      if (res.data && res.data.length > 0) {
        setCustomer(res.data[0]);
      } else {
        StripeService.createStripeCustomer(me.email).then((res) =>
          setCustomer(res)
        );
      }
    });
  }, []);

  useEffect(() => {
    console.log("customer", customer);
  }, [customer]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const cardElement = elements.getElement(CardElement);

    stripe
      .createPaymentMethod({
        type: "card",
        card: cardElement,
      })
      .then((result) => {
        if (result.error) {
          setLoading(false);
          setErrMessage(result.error.message);
        } else {
          addSourceToCustomer(customer.id, result.paymentMethod.id);
        }
      });
  };

  const addSourceToCustomer = (
    customerId: string,
    paymentMethodId: any
  ): Promise<any> => {
    try {
      return StripeService.addSourceToCustomer(customerId, paymentMethodId)
        .then((res) =>
          StripeService.setDefaultPaymentMethod(customer.id, paymentMethodId)
        )
        .then((res) => {
          createSubscription();
        });
    } catch (err) {
      setLoading(false);
      setErrMessage(err.message);
    }
  };

  const createSubscription = () => {
    let priceId = plan.stripeId;
    let customerId = customer.id;

    try {
      StripeService.createSubscription(priceId, customerId).then(
        async (result) => {
          console.log("subscription result", result);
          if (result.error) {
            setLoading(false);
            setErrMessage(result.error.message || "Error");
          } else {
            await firestore
              .collection("stripe")
              .doc(result.id)
              .set({
                ...result,
                userId: me.id,
                purchaseName: plan.name,
                amount: plan.price,
                timeCreated: new Date(),
              });

            // tara here
            await firestore.collection("users").doc(me.id).update({
              subscriptionId: customerId,
              subscriptionKind: "stripe",
            });

            let customer = await firestore
              .collection("customers")
              .doc(me.id)
              .get();
            if (customer.exists) {
              await firestore.collection("customers").doc(me.id).update({
                subscription: customerId,
                subscriptionKind: "stripe",
              });
            } else {
              await firestore.collection("customers").doc(me.id).set({
                subscription: customerId,
                stripeId: customerId,
                subscriptionKind: "stripe",
              });
            }

            createBrand();
          }
        }
      );
    } catch (err) {
      setLoading(false);
      setErrMessage(err.message);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        height: 100,
      }}
    >
      <p style={{ color: "red", textAlign: "right" }}>{errMessage}</p>
      <div style={styles.cardContainer}>
        <CardElement options={STRIPE_OPTIONS} />
      </div>

      <div style={styles.buttonContainer}>
        {loading ? (
          <div style={styles.subscribeButton}>
            <DotLoader color={colors.white} loading={true} size={20} />
          </div>
        ) : (
          <button
            type="submit"
            disabled={!stripe || !(customer && customer.id)}
            style={styles.subscribeButton}
          >
            Purchase
          </button>
        )}
      </div>
    </form>
  );
}

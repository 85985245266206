import React, { useState } from "react";
import { AiOutlineDown, AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import logo from "../assets/logo-high.png";
import colors from "../constants/colors";
import { ANDROID_LINK, IOS_LINK } from "../constants/urls";

export default function Footer({ isMobile }) {
  const styles = {
    container: {
      marginTop: 50,
      paddingBottom: 20,
      paddingTop: 30,
      width: "100vw",
    },
    topContainer: {
      display: "flex",
      flexDirection: isMobile ? ("column" as const) : ("row" as const),
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: isMobile ? 12 : "4rem",
      paddingRight: isMobile ? 12 : "4rem",
      color: "#788ca0",
      minHeight: isMobile ? 150 : 200,
    },
    centerRow: {
      display: "flex",
      flexDirection: "row" as const,
      alignItems: "center",
    },
    logo: {
      width: 200,
      height: 100,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundImage: "url(" + logo + ")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "contain",
    },
    buttonsContainer: {
      display: "flex",
      flexDirection: "row" as const,
      justifyContent: "center",
      marginTop: 20,
      marginBottom: 20,
    },
    webButtonContainer: {
      display: "flex",
      flexDirection: "column" as const,
      flex: 3,
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
    iosButton: {
      width: isMobile ? 145 : 175,
      height: isMobile ? 46 : 56,
      cursor: "pointer",
      border: "1px solid rgba(256, 256, 256, 0.6)",
      borderRadius: 8,
    },
    androidButton: {
      width: isMobile ? 145 : 175,
      height: isMobile ? 46 : 56,
      cursor: "pointer",
    },
    centerCol: {
      display: "flex",
      flexDirection: "column" as const,
      flex: 1,
      alignItems: "center",
    },
    leftCol: {
      display: "flex",
      flexDirection: "column" as const,
      flex: 1,
      width: "100%",
      paddingLeft: 30,
    },
    link: {
      color: "#788ca0",
      textDecoration: "none" as const,
      marginBottom: 6,
      textAlign: "center" as const,
    },
    leftlink: {
      color: "#788ca0",
      textDecoration: "none" as const,
      marginBottom: 6,
      textAlign: "left" as const,
    },
    rowHeader: {
      fontWeight: "bold" as const,
      color: "#eef5f8",
      marginBottom: 10,
      marginTop: 10,
    },
  };

  const [openIndex, setOpenIndex] = useState(-1);

  const renderCollapsibleHeader = (index: number, text: string) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          paddingLeft: 20,
          paddingRight: 10,
        }}
        onClick={() => {
          if (openIndex == index) {
            setOpenIndex(-1);
          } else {
            setOpenIndex(index);
          }
        }}
      >
        <p style={styles.rowHeader}>{text}</p>

        {index == openIndex ? (
          <AiOutlineDown
            style={{ color: colors.white, paddingTop: 8, fontSize: 30 }}
          />
        ) : (
          <AiOutlineRight
            style={{ color: colors.white, paddingTop: 8, fontSize: 30 }}
          />
        )}
      </div>
    );
  };
  if (isMobile) {
    return (
      <div style={styles.container}>
        <div style={styles.topContainer}>
          <div style={styles.centerRow}>
            <div style={styles.logo} />
            <p style={{ textAlign: "left" }}>
              The platform for the next generation of creatives.
            </p>
          </div>

          <div style={styles.buttonsContainer}>
            <img
              onClick={() => window.open(IOS_LINK, "_blank")}
              src={require("../assets/ios-button.png")}
              style={styles.iosButton}
            />
            <div style={{ width: 20 }} />
            <img
              onClick={() => window.open(ANDROID_LINK, "_blank")}
              src={require("../assets/android-button.png")}
              style={styles.androidButton}
            />
          </div>
          <div style={styles.centerCol}>
            {renderCollapsibleHeader(0, "Company")}
            {openIndex == 0 ? (
              <div style={styles.leftCol}>
                <a
                  href="https://angel.co/company/avntspace"
                  style={styles.leftlink}
                >
                  Jobs
                </a>

                <a
                  href="https://www.termsfeed.com/live/f3ab5cda38fb65ee9e713f95f014cebc "
                  style={styles.leftlink}
                >
                  Terms of Service
                </a>

                <a
                  href="https://www.termsfeed.com/live/f3ab5cda38fb65ee9e713f95f014cebc"
                  style={styles.leftlink}
                >
                  Privacy Policy
                </a>
              </div>
            ) : (
              <div />
            )}
          </div>
          <div style={styles.centerCol}>
            {renderCollapsibleHeader(1, "Resources")}
            {openIndex == 1 ? (
              <div style={styles.leftCol}>
                <a
                  href="https://www.instagram.com/avntspace/"
                  style={styles.leftlink}
                >
                  Instagram
                </a>
                <a href="https://twitter.com/avntspace" style={styles.leftlink}>
                  Twitter
                </a>
                <a href="mailto:contact@avnt.space" style={styles.leftlink}>
                  Contact
                </a>
              </div>
            ) : (
              <div />
            )}
          </div>
          <div style={styles.centerCol}>
            {renderCollapsibleHeader(2, "Account")}
            {openIndex == 2 ? (
              <div style={styles.leftCol}>
                <Link to="/testimonials" style={styles.leftlink}>
                  Testimonials
                </Link>
                <a href="https://avnt.helpcenter.io/" style={styles.leftlink}>
                  Help Center
                </a>
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
        <p style={{ opacity: 0.5, fontSize: 12, textAlign: "center" }}>
          Copyright 2022, AVNT, Inc. All Rights Reserved.
        </p>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <div style={styles.topContainer}>
        <div style={styles.logo} />

        <div style={styles.webButtonContainer}>
          <p style={{ textAlign: "left" }}>
            The platform for the next generation of creatives
          </p>
          <div style={styles.buttonsContainer}>
            <img
              onClick={() => window.open(IOS_LINK, "_blank")}
              src={require("../assets/ios-button.png")}
              style={styles.iosButton}
            />
            <div style={{ width: 20 }} />
            <img
              onClick={() => window.open(ANDROID_LINK, "_blank")}
              src={require("../assets/android-button.png")}
              style={styles.androidButton}
            />
          </div>

          <p style={{ opacity: 0.5, fontSize: 12 }}>
            Copyright 2022, AVNT, Inc. All Rights Reserved.
          </p>
        </div>

        <div style={styles.centerCol}>
          <p style={styles.rowHeader}>Company</p>
          <a href="https://angel.co/company/avntspace" style={styles.link}>
            Jobs
          </a>

          <a
            href="https://www.termsfeed.com/live/f3ab5cda38fb65ee9e713f95f014cebc "
            style={styles.link}
          >
            Terms of Service
          </a>

          <a
            href="https://www.termsfeed.com/live/f3ab5cda38fb65ee9e713f95f014cebc"
            style={styles.link}
          >
            Privacy Policy
          </a>
        </div>
        <div style={styles.centerCol}>
          <p style={styles.rowHeader}>Resources</p>

          <a href="https://www.instagram.com/avntspace/" style={styles.link}>
            Instagram
          </a>
          <a href="https://twitter.com/avntspace" style={styles.link}>
            Twitter
          </a>
          <a href="mailto:contact@avnt.space" style={styles.link}>
            Contact
          </a>
        </div>
        <div style={styles.centerCol}>
          <p style={styles.rowHeader}>Account</p>

          <Link to="/testimonials" style={styles.link}>
            Testimonials
          </Link>
          <a href="https://avnt.helpcenter.io/" style={styles.link}>
            Help Center
          </a>
        </div>
      </div>
    </div>
  );
}

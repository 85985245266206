import React from "react";
import { LimitedBrand, useFeaturedBrands } from "@avntspace/avnt-lib-firebase";
import { useFirestoreConnect } from "react-redux-firebase";
import BrandCircle from "../map/brand-circle";

export default function FeaturedBrandsRow(props: {
  grid?: boolean;
  textColor?: string;
  small?: boolean;
}) {
  const styles = {
    listContainer: {
      display: "flex",
      flexDirection: "row" as const,

      height: "30vh",
      overflow: "scroll",
    },
    gridContainer: {
      display: "flex",
      flexDirection: "row" as const,
      flexWrap: "wrap" as const,
      justifyContent: "center" as const,
      minHeight: "60vh",
    },
  };

  const brandData = useFeaturedBrands();

  useFirestoreConnect([
    {
      collection: "brands",
      where: ["isFeatured", "==", true],
      storeAs: "featuredBrands",
    },
  ]);

  const filteredBrandData = () => {
    let b = brandData || [];

    return b.filter((item) => item.imageFile);
  };

  return (
    <div style={props.grid ? styles.gridContainer : styles.listContainer}>
      {filteredBrandData().map((item: LimitedBrand, index: number) => (
        <BrandCircle
          key={index}
          brand={item}
          index={index}
          titleColor={props.textColor}
          small={props.small}
        />
      ))}
    </div>
  );
}

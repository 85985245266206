import React from "react";
import { LimitedBrand } from "@avntspace/avnt-lib-firebase";
import GoogleMapReact from "google-map-react";
import colors from "../../constants/colors";
import MapMarker from "./map-marker";
import shirt from "../../assets/shirt.png";

const Marker = (props: { lat: number; lng: number }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: 30,
          height: 30,

          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          backgroundImage: "url(" + shirt + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",

          overflow: "hidden",
        }}
      ></div>
    </div>
  );
};
export default function ReadonlyMap(props: {
  brands: any[];
  height?: any;
  width?: any;
  isMobile?: boolean;
}) {
  const styles = {
    mapContainer: {
      height: props.height ? props.height : props.isMobile ? "65vh" : "80vh",
      width: props.width ? props.width : props.isMobile ? "100vw" : "90vw",
      overflow: "hidden",
      backgroundColor: colors.black,
    },
  };

  return (
    <div style={styles.mapContainer}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyACIpmWZsX2yUqawS4EWpYfYWKVi_6kCZc" }}
        defaultCenter={{
          lat: 39.3,
          lng: -97.67,
        }}
        defaultZoom={4.8}
      >
        {(props.brands || []).map((item: any, index: number) => (
          <Marker lat={item.latitude} lng={item.longitude} />
        ))}
      </GoogleMapReact>
    </div>
  );
}

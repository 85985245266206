import Testimonial from "./testimonial";
import React from "react";
import { useTestimonials } from "@avntspace/avnt-lib-firebase";
import { useFirestoreConnect } from "react-redux-firebase";

export default function TestimonialsGrid(props: { kind: string }) {
  const testimonials = useTestimonials();

  useFirestoreConnect([
    {
      collection: "testimonials",
    },
  ]);

  const getColor = (index) => {
    switch (index % 4) {
      case 0:
        return "gray";
      case 1:
        return "violet";

      case 2:
        return "white";
      case 3:
        return "blue";
    }
  };

  return (
    <section className="testimonials-grid">
      {(testimonials || [])
        .filter((item) => (item as any).kind == props.kind)
        .map((testimonial: any, index: number) => (
          <Testimonial
            key={testimonial.id}
            testimonial={{
              ...testimonial,
              bgcolor: getColor(index),
              id: index,
            }}
          />
        ))}
    </section>
  );
}

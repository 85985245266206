import React, { useEffect, useState } from "react";
import {
  isLoaded,
  useFirestore,
  useFirestoreConnect,
} from "react-redux-firebase";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { InterviewBlock } from "@avntspace/avnt-lib-firebase";
import PulseLoader from "react-spinners/PulseLoader";
import colors from "../../constants/colors";
import ReactHtmlParser from "react-html-parser";
import MediaHeader from "./components/media-header";
import firebase from "firebase";
import { defaultAnalytics } from "../../firebase/store";

export default function InterviewPage({ isMobile }) {
  const styles = {
    headerContainer: {
      display: "flex",
      flexDirection: "column" as const,

      alignItems: "center",
      minHeight: "100vh",
    },
    imageRow: {
      marginTop: 30,
      marginBottom: 30,
      display: "flex",
      flexDirection: "row" as const,
      width: isMobile ? "90vw" : "70vw",
      overflowX: "scroll" as const,
    },
    image: {
      flex: 1,
      justifyContent: "center" as const,
      display: "flex",
    },
    largeImage: {
      height: "auto",
      width: isMobile ? "90vw" : "70vw",
    },
    regularImage: {
      height: 350,
      width: "auto",
      marginLeft: 15,
      marginRight: 15,
    },
    brightTextBos: {
      backgroundColor: colors.orange,
      marginTop: 30,
      marginBottom: 30,
      padding: isMobile ? 20 : 80,
    },
  };

  let { id } = useParams<any>();
  const currentInterviewData = useSelector(
    (state: any) => state.firestore.data.currentInterview
  );
  const [post, setPost] = useState(null);
  const firestore = useFirestore();

  useFirestoreConnect([
    {
      collection: "interviews",
      where: ["url", "==", `https://avnt.space/brands/${id}`],
      storeAs: "currentInterview",
    },
  ]);

  useEffect(() => {
    if (post != null) {
      var postRef = firestore.collection("interviews").doc(post.id);

      postRef.update({
        viewCount: firebase.firestore.FieldValue.increment(1),
      });

      defaultAnalytics.logEvent("view_interview", {
        interviewId: post.id,
        interviewName: post.title,
      });
    }
  }, [post]);

  if (
    post == null &&
    isLoaded(currentInterviewData) &&
    Object.keys(currentInterviewData).length > 0
  ) {
    let posts =
      currentInterviewData &&
      Object.keys(currentInterviewData).map((key) => ({
        ...currentInterviewData[key],
        id: key,
      }));
    posts = posts.filter(
      (item) => item.url == `https://avnt.space/brands/${id}`
    );
    if (posts.length > 0) {
      setPost(posts[0]);
    }
  }

  const renderContentBlock = (content: InterviewBlock, ind: number) => {
    return (
      <div key={ind}>
        <div
          style={{
            marginTop: 30,
            marginBottom: 30,
            marginLeft: isMobile ? 10 : 0,
            marginRight: isMobile ? 10 : 0,
            color: colors.orange,
            fontSize: isMobile ? 18 : 24,
            fontWeight: 800,
          }}
        >
          {ReactHtmlParser(content.question)}
        </div>
        <div
          style={{
            marginTop: 30,
            marginBottom: 30,
            fontSize: isMobile ? 14 : 16,
            marginLeft: isMobile ? 10 : 0,
            marginRight: isMobile ? 10 : 0,
          }}
        >
          {ReactHtmlParser(content.answer)}
        </div>
        <div style={styles.imageRow}>
          {(content.images || []).map((item, index) => (
            <div style={styles.image} key={`img-${ind}-${index}`}>
              <img src={item.src} style={styles.regularImage} />
            </div>
          ))}
        </div>
      </div>
    );
  };

  return post ? (
    <div style={styles.headerContainer}>
      <MediaHeader post={post} isMobile={isMobile} />
      <div style={{ width: isMobile ? "90vw" : "70vw" }}>
        {(post.content || []).map((item, index) =>
          renderContentBlock(item, index)
        )}
      </div>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        marginTop: 12,
      }}
    >
      <PulseLoader color={colors.orange} loading={true} size={20} margin={3} />
    </div>
  );
}

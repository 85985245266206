import React, { useEffect, useState, Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { spring, AnimatedSwitch } from "react-router-transition";
import { Provider } from "react-redux";
import { createHashHistory } from "history";
import firebase from "firebase";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import createStore from "../firebase/store";
import { PersistGate } from "redux-persist/integration/react";
import Home from "../pages/home-page/home";
import Header from "../header/nav-header";
import Menu from "../header/menu";
import Footer from "../footer/nav-footer";
import TestimonialPage from "../pages/testimonials/testimonials";
import BecomeCreatorPage from "../pages/become-creator-page/become-creator-page";
import BrandsPage from "../pages/brands-page/brands-page";
import MediaPage from "../pages/media-page/media-page";
import DownloadPage from "../pages/download-page/download-page";
import ShopifyPage from "../pages/shopify-page/shopify-page";
import ServicesPage from "../pages/services-page/services-page";
import ShopPage from "../pages/shop-page/shop-page";
import InterviewPage from "../pages/media-page/interview-page";
import BlogPostPage from "../pages/media-page/blog-post-page";
import CreatorSetupPage from "../pages/creator-setup-page/creator-setup";
import CreatorPaymentPage from "../pages/creator-setup-page/creator-payment";
import "bootstrap";
import "bootstrap/dist/js/bootstrap.js";
import "../App.css";
import "../styles/App.scss";
import ScrollToTop from "./scroll-top";

function bounce(val) {
  return spring(val, {
    stiffness: 330,
    damping: 22,
  });
}

const bounceTransition = {
  atEnter: {
    opacity: 0.5,
    scale: 1.2,
  },
  atLeave: {
    opacity: bounce(0),
    scale: bounce(0.8),
  },
  atActive: {
    opacity: bounce(1),
    scale: bounce(1),
  },
};

const history = createHashHistory();

const initialState = {};

const { store, persistor } = createStore(initialState, history);

const rfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true,
  useFirestoreForStorageMeta: true,
};

export default function App() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  const isMobile = () => {
    return width < 768;
  };

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ReactReduxFirebaseProvider
          firebase={firebase}
          config={rfConfig}
          dispatch={store.dispatch}
          createFirestoreInstance={createFirestoreInstance}
        >
          <Router>
            {isMobile() ? <Menu /> : <div />}

            <ScrollToTop />
            <AnimatedSwitch
              atEnter={{ opacity: 0 }}
              atLeave={{ opacity: 2 }}
              atActive={{ opacity: 1 }}
              mapStyles={(styles) => {
                if (styles.opacity > 1) {
                  return { display: "none" };
                }
                return { opacity: styles.opacity };
              }}
              className="route-wrapper"
            >
              <Route path="/club/:packageId">
                <CreatorPaymentPage isMobile={isMobile()} />
              </Route>
              <Route path="/club">
                <BecomeCreatorPage isMobile={isMobile()} />
              </Route>
              <Route path="/testimonials">
                <TestimonialPage isMobile={isMobile()} />
              </Route>

              {/* <Route path="/creator-setup/:packageId">
                <CreatorSetupPage isMobile={isMobile()} />
              </Route> */}

              <Route path="/discover">
                <BrandsPage isMobile={isMobile()} />
              </Route>
              <Route path="/download">
                <DownloadPage isMobile={isMobile()} />
              </Route>

              <Route path="/shopify-redirect">
                <ShopifyPage isMobile={isMobile()} />
              </Route>

              <Route path="/media">
                <MediaPage isMobile={isMobile()} />
              </Route>
              <Route path="/brands/:id">
                <InterviewPage isMobile={isMobile()} />
              </Route>
              <Route path="/blog-post/:id">
                <BlogPostPage isMobile={isMobile()} />
              </Route>
              <Route path="/shop">
                <ShopPage isMobile={isMobile()} />
              </Route>
              <Route path="/">
                <Home isMobile={isMobile()} />
              </Route>
            </AnimatedSwitch>
            <Footer isMobile={isMobile()} />

            <nav>
              <Header isMobile={isMobile()} />
            </nav>
          </Router>
        </ReactReduxFirebaseProvider>
      </PersistGate>
    </Provider>
  );
}

import {
  useInterviews,
  useMedia,
  useTopTenPicks,
} from "@avntspace/avnt-lib-firebase";
import React, { useState } from "react";
import { useFirestoreConnect } from "react-redux-firebase";
import BlogPostCard from "./components/blog-post-card";
import { PageSubtitle } from "../../components/text";
import ThreeItemSelect from "../../components/buttons/three-item-select";
import TopTenPicksCard from "./components/top-ten-picks-card";
import Select from "react-select";
import colors from "../../constants/colors";
import MediaCard from "./components/media-card";

const options = [
  { value: "newest", label: "Newest" },
  { value: "oldest", label: "Oldest" },
  { value: "popular", label: "Most Popular" },
];

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    minWidth: 120,
    width: state.selectProps.width,
    color: state.selectProps.menuColor,
  }),
  control: () => ({
    display: "flex",
    borderColor: colors.orange,
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    color: colors.orange,
  }),
  singleValue: () => ({
    display: "flex",
    minWidth: 120,
    color: colors.orange,
    textAlign: "right",
  }),
  placeholder: () => ({
    color: colors.orange,
  }),
  indicatorSeparator: () => ({ opacity: 0 }),
};

export default function MediaPage({ isMobile }) {
  const interviews = useInterviews();
  const media = useMedia();
  const topTenPicks = useTopTenPicks();
  const [currentFilter, setCurrentFilter] = useState({
    value: "newest",
    label: "Newest",
  });

  useFirestoreConnect([
    {
      collection: "interviews",
      orderBy: ["publishDate", "desc"],
    },
    {
      collection: "media",
      orderBy: ["publishDate", "desc"],
    },
    {
      collection: "topTenPicks",
      orderBy: ["ordering", "asc"],
    },
  ]);

  const [selected, setSelected] = useState("Media");
  const styles = {
    headerContainer: {
      display: "flex",
      flexDirection: "column" as const,
      marginLeft: isMobile ? 12 : 100,
      marginRight: isMobile ? 12 : 100,
      marginTop: isMobile ? 50 : 100,
      alignItems: "center",
      minHeight: "100vh",
    },
  };

  const sortItems = (items) => {
    let sorted = [...(items || [])];

    switch (currentFilter.value) {
      case "newest":
        break;
      case "oldest":
        return sorted.reverse();

      case "popular":
        return sorted.sort((a, b) => {
          if (a.viewCount && b.viewCount) {
            if (a.viewCount > b.viewCount) {
              return -1;
            }
            if (a.viewCount < b.viewCount) {
              return 1;
            }
          }

          return 0;
        });
    }

    return sorted;
  };

  const renderInterviews = () => {
    return selected === "Articles" ? (
      <div style={{ display: "flex", flexDirection: "column", marginTop: 40 }}>
        {sortItems(interviews)
          .filter((item) => item.content)
          .map((item, index) => (
            <BlogPostCard
              key={index}
              blogPost={item}
              index={index}
              interview={true}
              isMobile={isMobile}
            />
          ))}
      </div>
    ) : (
      <div />
    );
  };

  const renderTopTen = () => {
    return selected === "Top 10 Picks" ? (
      <div style={{ display: "flex", flexDirection: "column", marginTop: 40 }}>
        {(topTenPicks || []).map((item, index) => (
          <TopTenPicksCard
            pick={item}
            index={index}
            isMobile={isMobile}
            key={index}
          />
        ))}
      </div>
    ) : (
      <div />
    );
  };

  const renderMedia = () => {
    return selected === "Media" ? (
      <div style={{ display: "flex", flexDirection: "column", marginTop: 40 }}>
        {sortItems(media).map((item, index) => (
          <MediaCard
            key={index}
            media={item}
            index={index}
            isMobile={isMobile}
          />
        ))}
      </div>
    ) : (
      <div />
    );
  };

  return (
    <div style={styles.headerContainer}>
      <div
        style={{
          display: "flex",
          flexDirection: "column" as const,

          alignItems: "center",
        }}
      >
        <PageSubtitle title="The platform for the next generation of creatives" />

        <ThreeItemSelect
          itemOne={"Media"}
          itemTwo={"Top 10 Picks"}
          itemThree={"Articles"}
          selected={selected}
          onSelectOne={() => setSelected("Media")}
          onSelectTwo={() => setSelected("Top 10 Picks")}
          onSelectThree={() => setSelected("Articles")}
          fontSize={14}
        />
      </div>
      <div
        style={{
          marginTop: isMobile ? 12 : -40,

          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",

          width: "85vw",
          marginLeft: isMobile ? 0 : 20,
        }}
      >
        <Select
          isMulti={false}
          options={options}
          styles={customStyles}
          menuColor={colors.orange}
          onChange={(e) => setCurrentFilter(e)}
          value={currentFilter}
        />
      </div>

      {renderInterviews()}
      {renderTopTen()}
      {renderMedia()}
    </div>
  );
}

import { Interview } from "@avntspace/avnt-lib-firebase";
import moment from "moment";
import React from "react";
import { PageSubtitle, PageTitle } from "../../../components/text";
import { FaInstagram, FaTwitter } from "react-icons/fa";
import { MdWeb } from "react-icons/md";
import colors from "../../../constants/colors";

export default function MediaHeader(props: {
  post: any | Interview;
  isMobile: boolean;
}) {
  return (
    <div
      style={{
        backgroundImage: "url(" + props.post.mainImage.src + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        width: "100vw",
        height: props.isMobile ? "80vh" : "100vh",
      }}
    >
      <div
        style={{
          background:
            "linear-gradient(0deg, rgba(14,17,17,1) 5%,  rgba(14,17,17,0) 100%)",
          width: "100vw",
          height: props.isMobile ? "80vh" : "100vh",
          padding: "10vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <PageTitle title={props.post.title} />
        <PageSubtitle title={props.post.subtitle} />
        <p style={{ opacity: 0.7 }}>
          {moment(props.post.publishDate).fromNow()}
        </p>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            color: colors.orange,
            marginTop: 8,
            marginBottom: 20,
          }}
        >
          {props.post.instagramLink ? (
            <a href={props.post.instagramLink} target="_blank">
              <FaInstagram style={{ width: 40, height: 40, marginRight: 12 }} />
            </a>
          ) : (
            <div />
          )}
          {props.post.twitterLink ? (
            <a href={props.post.twitterLink} target="_blank">
              <FaTwitter style={{ width: 40, height: 40, marginRight: 12 }} />
            </a>
          ) : (
            <div />
          )}
          {props.post.webLink ? (
            <a href={props.post.webLink} target="_blank">
              <MdWeb style={{ width: 40, height: 40, marginRight: 12 }} />
            </a>
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  );
}

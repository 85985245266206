import React from "react";
import colors from "../../../constants/colors";
import moment from "moment";
import { useHistory } from "react-router-dom";

export default function BlogPostCard(props: {
  blogPost: any;
  index: number;
  interview: boolean;
  isMobile: boolean;
}) {
  const styles = {
    container: {
      display: "flex",
      flexDirection: props.isMobile ? ("column" as const) : ("row" as const),

      flex: 1,
      margin: 15,
      minHeight: 200,
    },
  };

  const history = useHistory();

  return (
    <div
      style={styles.container}
      key={props.index}
      onClick={() => {
        if (props.interview) {
          history.push(
            `brands/${props.blogPost.url.replace(
              "https://avnt.space/brands/",
              ""
            )}`
          );
        } else {
          history.push(`blog-post/${props.blogPost.id}`);
        }
      }}
    >
      <div
        style={{
          backgroundColor: colors.orange,
          backgroundImage: "url(" + props.blogPost.mainImage.src + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          width: 300,
          minWidth: 300,
          height: 200,
          marginRight: 20,
        }}
      />
      <div>
        <p
          style={{
            textAlign: "left",
            color: colors.orange,
            fontSize: 22,
            fontWeight: 700,
          }}
        >
          {props.blogPost.title}
        </p>
        <p style={{ textAlign: "left" }}>{props.blogPost.subtitle}</p>

        <p style={{ textAlign: "left" }}>
          {props.blogPost.introText
            ? props.blogPost.introText.slice(0, 200) + "..."
            : props.blogPost.content[0].text.replace("<p>", "").slice(0, 200) +
              "..."}
        </p>

        <p style={{ textAlign: "left", opacity: 0.7 }}>
          {moment(props.blogPost.publishDate).fromNow()}
        </p>
      </div>
    </div>
  );
}

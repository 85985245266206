import React from "react";
import {
  Activity,
  Release,
  ReleaseService,
  useMe,
} from "@avntspace/avnt-lib-firebase";
import colors from "../../constants/colors";
import { defaultAnalytics } from "../../firebase/store";
import { useFirestore } from "react-redux-firebase";
import moment from "moment";

export default function ReleaseCard(props: {
  release: Release;
  index: number;
  isMobile: boolean;
}) {
  const CARD_WIDTH = props.isMobile ? 280 : 350;
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column" as const,
      width: CARD_WIDTH,
      minWidth: CARD_WIDTH,
      maxWidth: CARD_WIDTH,
      flex: 1,
      margin: 15,
      minHeight: 200,
      borderColor: colors.orange,
      borderWidth: 2,
      borderStyle: "solid",
      borderRadius: 12,
      padding: 12,
      paddingBottom: 8,
      paddingTop: 8,
      boxShadow: "3px 3px 16px rgba(234, 150, 17, 0.8)",
    },
    imageRow: {
      flex: 1,

      display: "flex",
      flexDirection: "row" as const,
      paddingBottom: 8,
    },
    textCol: {
      flex: 1,

      display: "flex",
      flexDirection: "column" as const,
      padding: 12,
      paddingBottom: 0,
      paddingTop: 0,
      alignItems: "center",
      justifyContent: "space-between",
    },
  };

  const me = useMe();
  const firestore = useFirestore();

  const visitReleaseURL = async (release: Release) => {
    let url = props.release.website
      ? props.release.website.includes("http")
        ? props.release.website
        : `http://${props.release.website}`
      : "http://avnt.space";

    if (props.isMobile) {
      url = `https://avntspace.page.link/?link=https://avnt.space/release?releaseId=${release.id}&brandId=${release.brandId}&apn=avntspace.avntapp&isi=1486420898&ibi=avntspace.avntapp&ius=avntspace`;
    }

    await defaultAnalytics.logEvent("shop", {
      releaseId: release.id,
      brandId: release.brandId,
      releaseName: release.releaseTitle,
    });

    let activity: Activity = {
      createdAt: new Date(),
      kind: "shop",
      brandId: release.brandId,
      releaseId: release.id,
      notificationTime: release.notificationTime,
      userId: me && me.id ? me.id : "anon",
    };

    firestore.collection("activity").add(activity);

    window.open(url, "_blank");
  };

  return (
    <div
      style={styles.container}
      key={props.index}
      onClick={() => visitReleaseURL(props.release)}
    >
      <div>
        <p
          style={{
            textAlign: "center",
            color: colors.orange,
            fontSize: 22,
            fontWeight: 700,
            marginTop: 8,
            marginBottom: 8,
          }}
        >
          {props.release.brandName.toUpperCase()}
        </p>
      </div>
      <div style={styles.imageRow}>
        <div
          style={{
            backgroundImage: "url(" + props.release.imageUrl + ")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            width: CARD_WIDTH / 2 - 30,
            minWidth: CARD_WIDTH / 2 - 30,
            borderRadius: 12,
            overflow: "hidden",
          }}
        ></div>
        <div style={styles.textCol}>
          <p style={{ textAlign: "center" }}>
            {props.release.releaseTitle.replaceAll('"', "")}
          </p>
          <div style={{ fontSize: 12 }}>
            <p style={{ textAlign: "center", marginBottom: 2 }}>
              {ReleaseService.dropdateForRelease(props.release)}
            </p>

            <p style={{ textAlign: "center", opacity: 0.7 }}>
              {/* @ts-ignore */}
              {ReleaseService.notificationTimeString(props.release, null)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import { LimitedBrand, useAllBrands } from "@avntspace/avnt-lib-firebase";
import { useFirestoreConnect } from "react-redux-firebase";
import BrandCircle from "../../components/map/brand-circle";
import colors from "../../constants/colors";
import BrandSearch from "../../components/dropdowns/brand-search";
import Map from "../../components/map/map";
import TwoItemSelect from "../../components/buttons/two-item-select";
import { PageTitle } from "../../components/text";
import FeaturedBrandsRow from "../../components/brands/featured-brands-row";

export default function BrandsPage({ isMobile }) {
  const styles = {
    headerContainer: {
      display: "flex",
      flexDirection: "column" as const,
      alignItems: "center",
      justifyContent: "center",
      marginTop: isMobile ? 60 : 100,
    },

    searchRow: {
      flexDirection: "row" as const,
      display: "flex",
      justifyContent: isMobile ? "center" : "space-between",
      width: "90vw",

      marginBottom: 20,
      color: colors.orange,
      fontSize: 18,
      marginTop: isMobile ? 0 : -40,
    },
    listContainer: {
      display: "flex",
      flexDirection: "row" as const,
      flexWrap: "wrap" as const,
      justifyContent: "center" as const,
      minHeight: "60vh",
    },
    featuredRowContainer: {
      paddingTop: 20,
      marginTop: 30,
      width: "100vw",
    },
    featuredRowTitle: {
      textAlign: "center" as const,
      fontWeight: "bold" as const,
      fontSize: 24,
      marginBottom: isMobile ? 10 : -20,
    },
    allBrandsTitle: {
      textAlign: "center" as const,
      color: "#fff",
      fontWeight: "bold" as const,
      fontSize: 24,
      marginTop: 40,
    },
  };

  const [showList, setShowList] = useState(isMobile ? true : false);
  const [query, setQuery] = useState("");

  const brandData = useAllBrands();

  useFirestoreConnect([
    {
      collection: "activeBrands",
    },
  ]);

  const filteredBrandData = () => {
    let b = brandData || [];

    if (query !== "") {
      b = b.filter((item: LimitedBrand) =>
        item.brandTitle.toLowerCase().includes(query.toLowerCase())
      );
    }
    return b.filter((item) => item.imageFile);
  };

  const renderList = () => {
    return (
      <div style={styles.listContainer}>
        {filteredBrandData().map((item: LimitedBrand, index: number) => (
          <BrandCircle
            brand={item}
            index={index}
            small={isMobile}
            titleColor={"white"}
          />
        ))}
      </div>
    );
  };

  return (
    <div style={styles.headerContainer}>
      <PageTitle title="DISCOVER" />

      <div style={styles.searchRow}>
        <TwoItemSelect
          itemOne={"MAP"}
          itemTwo={"BRANDS"}
          selected={showList ? "BRANDS" : "MAP"}
          onSelectOne={() => setShowList(false)}
          onSelectTwo={() => setShowList(true)}
        />

        {isMobile ? (
          <div />
        ) : (
          <div style={{ zIndex: 99 }}>
            <BrandSearch
              brands={brandData || []}
              onQueryChange={(q: string) => setQuery(q)}
              selectBrand={(val: LimitedBrand[]) => {
                if (val.length > 0) {
                  let brand = val[0];
                  window.open(
                    brand.brandWebsite
                      ? brand.brandWebsite.includes("http")
                        ? brand.brandWebsite
                        : `http://${brand.brandWebsite}`
                      : "http://avnt.space",
                    "_blank"
                  );
                }
              }}
              withMenu={showList ? false : true}
            />
          </div>
        )}
      </div>

      {showList && query == "" ? (
        <div>
          <div style={styles.featuredRowContainer}>
            <p style={styles.featuredRowTitle}>FEATURED</p>
            <FeaturedBrandsRow
              textColor={colors.white}
              grid={true}
              small={isMobile}
            />
          </div>
          <p style={styles.allBrandsTitle}>ALL BRANDS</p>
        </div>
      ) : (
        <div />
      )}

      {showList ? (
        renderList()
      ) : (
        <Map isMobile={isMobile} brands={filteredBrandData()} />
      )}
    </div>
  );
}

import React from "react";
import { PageSubtitle, PageTitle } from "../../components/text";
import colors from "../../constants/colors";
import { ANDROID_LINK, IOS_LINK } from "../../constants/urls";

export default function ShopifyPage({ isMobile }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "90vw",
        marginLeft: "5vw",
        height: "100vh",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginRight: 30,
          }}
        >
          <img
            src={require("../../assets/shop-1.png")}
            style={{ height: "80vh" }}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PageTitle title="Thanks for connecting AVNT with Shopify" />
          <PageSubtitle title="Continue to the mobile app to finish setting up your brand." />
          <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
            <img
              onClick={() => window.open(IOS_LINK, "_blank")}
              src={require("../../assets/ios-button.png")}
              style={{
                width: 250,
                height: 80,
                cursor: "pointer",
                border: "1px solid rgba(256, 256, 256, 0.6)",
                borderRadius: 8,
              }}
            />
            <div style={{ width: 50 }} />
            <img
              onClick={() => window.open(ANDROID_LINK, "_blank")}
              src={require("../../assets/android-button.png")}
              style={{ width: 250, height: 80, cursor: "pointer" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import {
  useStocks,
  useTools,
  useUpcomingReleases,
} from "@avntspace/avnt-lib-firebase";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import ReleaseCard from "../../components/releases/release-card";
import InfiniteScroll from "react-infinite-scroll-component";
import TwoItemSelect from "../../components/buttons/two-item-select";
import PulseLoader from "react-spinners/PulseLoader";
import colors from "../../constants/colors";
import StockCard from "../../components/releases/stock-card";
import { PageTitle } from "../../components/text";
import CategorySelect from "../../components/dropdowns/category-select";
import moment from "moment";
import ThreeItemSelect from "../../components/buttons/three-item-select";
import ToolCard from "../../components/releases/tool-card";

export default function ShopPage({ isMobile }) {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "row" as const,
      justifyContent: "center" as const,
      marginLeft: isMobile ? 20 : 100,
      marginRight: isMobile ? 20 : 100,
      marginTop: 100,
      minHeight: "100vh",
      maxWidth: "100vw",
      overflow: "hidden",
    },
    headerContainer: {
      display: "flex",
      flexDirection: "column" as const,
    },

    searchRow: {
      display: "flex",
      marginLeft: 20,
      marginRight: 20,
      flexDirection: isMobile ? ("column" as const) : ("row" as const),

      alignItems: "center",
      marginBottom: 20,
      fontSize: 18,

      justifyContent: "space-between",
    },
    releasesRow: {
      display: "flex",
      flexDirection: "row" as const,
      flexWrap: "wrap" as const,
      justifyContent: "center",
    },
    loaderContainer: {
      display: "flex",
      justifyContent: "center" as const,
      width: "100%",
      marginTop: 12,
    },
  };

  const releases = useUpcomingReleases();
  const stock = useStocks();
  const tools = useTools();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [limit, setLimit] = useState(10);
  const [stockLimit, setStockLimit] = useState(10);
  const [filters, setFilters] = useState([]);

  useFirestoreConnect([
    {
      collection: "releases",
      where: [
        ["notificationTime", ">", new Date()],
        ["approved", "==", true],
      ],
      limit: limit,
      orderBy: ["notificationTime", "asc"],
      storeAs: "upcomingReleases",
    },
    {
      collection: "releases",
      where: [
        ["notificationTime", "<", new Date()],
        ["approved", "==", true],
      ],
      orderBy: ["notificationTime", "desc"],
      limit: stockLimit,
      storeAs: "currentStock",
    },
    {
      collection: "tools",
    },
  ]);

  const filteredItems = (releaseItems) => {
    let items = releaseItems || [];
    if (filters.length > 0) {
      let keys = filters.map((item) => item.value);
      items = items.filter((item) => {
        let match = false;
        item.categories.forEach((c) => {
          if (keys.includes(c)) {
            match = true;
          }
        });
        return match;
      });
    }
    return items;
  };

  const fetchNext = () => {
    if (selectedIndex == 0) {
      setStockLimit(stockLimit + 10);
    } else if (selectedIndex == 1) {
      setLimit(limit + 10);
    }
  };

  const spacer = <div style={{ flex: 1 }}></div>;

  const renderChips = () => {
    return (releases || []).length > 0 ? (
      <CategorySelect filters={filters} setFilters={setFilters} />
    ) : (
      spacer
    );
  };

  const hasMoreStock = () => {
    if ((stock || []).length > 49 && filteredItems(stock).length == 0) {
      return false;
    }
    return (
      (stock || []).length % 10 === 0 && !(limit > (stock || []).length + 20)
    );
  };

  const hasMoreReleases = () => {
    if ((releases || []).length > 49 && filteredItems(releases).length == 0) {
      return false;
    }
    return (
      (releases || []).length % 10 === 0 &&
      !(limit > (releases || []).length + 20)
    );
  };

  const loader = (
    <div style={styles.loaderContainer}>
      <PulseLoader color={colors.orange} loading={true} size={20} margin={3} />
    </div>
  );

  const getVisibleCount = () => {
    switch (selectedIndex) {
      case 0:
        return filteredItems(stock).length;
      case 1:
        return filteredItems(releases).length;
      case 2:
        return (tools || []).length;
    }
    return 0;
  };

  let visibleCount = getVisibleCount();
  let fetchLimit = selectedIndex == 0 ? stockLimit : limit;
  let loadingMore =
    selectedIndex == 0
      ? hasMoreStock()
      : selectedIndex == 1
      ? hasMoreReleases()
      : false;
  if (
    filters.length > 0 &&
    visibleCount == 0 &&
    fetchLimit < 50 &&
    selectedIndex < 2
  ) {
    if (selectedIndex == 0) {
      setStockLimit(50);
    } else {
      setLimit(50);
    }
  }

  return (
    <div style={styles.container}>
      <div style={styles.headerContainer}>
        <PageTitle title={"SHOP NOW"} />

        <div style={{ minWidth: "50vw" }}>
          <div style={styles.searchRow}>
            {spacer}

            <div style={{ flex: 1, justifyContent: "center", display: "flex" }}>
              <ThreeItemSelect
                itemOne={"In Stock"}
                itemTwo={"Upcoming"}
                itemThree={"Tools"}
                selected={
                  selectedIndex == 0
                    ? "In Stock"
                    : selectedIndex == 1
                    ? "Upcoming"
                    : "Tools"
                }
                width={450}
                fontSize={isMobile ? 14 : 18}
                onSelectOne={() => setSelectedIndex(0)}
                onSelectTwo={() => setSelectedIndex(1)}
                onSelectThree={() => setSelectedIndex(3)}
              />
            </div>

            {renderChips()}
          </div>

          {filters.length > 0 && visibleCount == 0 && !loadingMore ? (
            <div style={styles.loaderContainer}>
              <p style={{ color: colors.white }}>
                {
                  "Sorry, nothing is currently available in this category. Try again soon."
                }
              </p>
            </div>
          ) : (
            <div />
          )}

          {selectedIndex == 0 ? (
            <InfiniteScroll
              dataLength={(stock || []).length}
              next={fetchNext}
              hasMore={hasMoreStock()}
              loader={loader}
            >
              <div style={styles.releasesRow}>
                {filteredItems(stock).map((item, index) => (
                  <StockCard
                    isMobile={isMobile}
                    release={item}
                    index={index}
                    key={index}
                  />
                ))}
              </div>
            </InfiniteScroll>
          ) : selectedIndex == 1 ? (
            <InfiniteScroll
              dataLength={(releases || []).length}
              next={fetchNext}
              hasMore={hasMoreReleases()}
              loader={loader}
            >
              <div style={styles.releasesRow}>
                {filteredItems(releases).map((item, index) => (
                  <ReleaseCard
                    key={index}
                    release={item}
                    index={index}
                    isMobile={isMobile}
                  />
                ))}
              </div>
            </InfiniteScroll>
          ) : (
            <InfiniteScroll
              dataLength={(tools || []).length}
              next={() => {}}
              hasMore={false}
              loader={loader}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  minWidth: "85vw",
                  maxWidth: "95vw",
                  overflowX: "hidden",
                  alignItems: "center",
                }}
              >
                {(tools || []).map((item, index) => (
                  <ToolCard
                    key={index}
                    release={item}
                    index={index}
                    isMobile={isMobile}
                  />
                ))}
              </div>
            </InfiniteScroll>
          )}
        </div>
      </div>
    </div>
  );
}

// import env from "react-dotenv";

// tara here env
// export const firebaseConfig = {
//   apiKey: env.API_KEY,
//   authDomain: env.AUTH_DOMAIN,
//   databaseURL: env.DATABASE_URL,
//   projectId: env.PROJECT_ID,
//   storageBucket: env.STORAGE_BUCKET,
//   messagingSenderId: env.MESSAGING_SENDER_ID,
//   appId: env.APP_ID,
//   measurementId: env.MEASUREMENT_ID,
// };

export const STRIPE_KEY = `pk_live_KANfbIwNyxrYYdEK2qjjLoBv`;
export const STRIPE_SECRET = `sk_live_AYJN4AaOwRG7cOKMxRlAf22a`;
export const PAYPAL_CLIENT_ID = `AZuuMPhX9fgd8zjF6Qd4CZ8y0NgitpJbp7dI4fkcXcXWhdkc5u85eMlNcNJaLdvwkQx8iiULj2X9hfPt`;

export const firebaseConfig = {
  apiKey: "AIzaSyACIpmWZsX2yUqawS4EWpYfYWKVi_6kCZc",
  authDomain: "avnt-ffa6c.firebaseapp.com",
  databaseURL: "https://avnt-ffa6c.firebaseio.com",
  projectId: "avnt-ffa6c",
  storageBucket: "avnt-ffa6c.appspot.com",
  messagingSenderId: "447127655047",
  appId: "1:447127655047:web:1d5e0d9b3db7bc1422de22",
  measurementId: "G-Z87N36JBT4",
};

// export const firebaseConfig = {
//   apiKey: "AIzaSyDtmo1WT64lquJQW67uNhrox5W1GNY_R4A",
//   authDomain: "avnt-staging.firebaseapp.com",
//   databaseURL: "https://avnt-staging.firebaseio.com",
//   projectId: "avnt-staging",
//   storageBucket: "avnt-staging.appspot.com",
//   messagingSenderId: "947056457881",
//   appId: "1:947056457881:web:a1b2efd7e23aabd590a008",
//   measurementId: "G-Z87N36JBT4",
// };

// export const STRIPE_KEY = `pk_test_21B2qpqgpxIvYZ6zCk7tEpIU`;
// export const STRIPE_SECRET = `sk_test_GYFHjEbUoOYC0idjmat2J7xp`;
// export const PAYPAL_CLIENT_ID = `Ab-oWPqJTWPRQMqEHG6bXaOQehHrNOo_Tk8XDUsF37gEQ7nj2XAItOTvi-w0ybaBWfAkNUzMgLl3J1vI`;

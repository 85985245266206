import React from "react";
import colors from "../../../constants/colors";
import { Release, TopTenPick } from "@avntspace/avnt-lib-firebase";
import ReleaseCard from "../../../components/releases/release-card";
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";

export default function TopTenPicksCard(props: {
  pick: TopTenPick;
  index: number;
  isMobile: boolean;
}) {
  const release = useSelector<string, Release>((state: any) =>
    state.firestore.data.releases &&
    Object.keys(state.firestore.data.releases).includes(props.pick.releaseId)
      ? state.firestore.data.releases[props.pick.releaseId]
      : null
  );
  useFirestoreConnect([{ collection: "releases", doc: props.pick.releaseId }]);
  const styles = {
    container: {
      display: "flex",
      flexDirection: props.isMobile ? ("column" as const) : ("row" as const),

      flex: 1,
      margin: 15,
      minHeight: 200,
      maxWidth: 800,
    },
  };

  return (
    <div style={styles.container} key={props.index}>
      <p
        style={{
          textAlign: "left",
          color: colors.orange,
          fontSize: 40,
          fontWeight: 700,
          marginTop: 12,
        }}
      >
        {release ? `#${props.pick.ordering}` : ""}
      </p>
      <div style={{ width: 400, minWidth: 400 }}>
        {release ? (
          <ReleaseCard
            release={{ ...release, id: props.pick.releaseId }}
            index={props.index}
            isMobile={props.isMobile}
          />
        ) : (
          <div />
        )}
      </div>

      <div>
        <p style={{ textAlign: "left", marginTop: 20 }}>{props.pick.comment}</p>
      </div>
    </div>
  );
}

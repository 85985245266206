import React from "react";
import { useHistory } from "react-router-dom";
import { CTA } from "../../../components/buttons/buttons";
import { PageTitle } from "../../../components/text";
import colors from "../../../constants/colors";

export default function Hero(props: { isMobile: boolean }) {
  const history = useHistory();
  return (
    <div style={{ marginTop: -500, zIndex: 999, marginBottom: 50 }}>
      <div>
        <h1>
          <span>
            <svg
              width="600px"
              height="300px"
              viewBox="0 0 1076.47 294.42"
              version="1.1"
            >
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill={"none"}
                fillRule="evenodd"
              >
                <path
                  d="M591.59,41.06V285.62c0,9.25.44,8.72-8.78,8.69-5.16,0-10.33.08-15.48,0-4-.09-4.47-.65-4.75-4.42-.1-1.38,0-2.78,0-4.17V10c0-9,0-9,9.24-9,8.54,0,17.08.13,25.61,0,3.82-.08,6.15,1.27,8.11,4.64q33.81,57.81,67.87,115.48,37.74,64.13,75.51,128.25c1.16,2,2.55,3.8,4.61,6.85a46.54,46.54,0,0,0,1.06-5.15c.14-1.78,0-3.57,0-5.36V9.2c0-8.22,0-8.22,8.1-8.24,5.16,0,10.32-.07,15.48,0,4.89.1,5.28.57,5.45,5.6V284.73c0,10.83.12,9.57-9.76,9.61-8.14,0-16.29-.23-24.41.1-4.37.18-6.9-1.5-9-5.15-13.18-22.63-26.51-45.18-39.8-67.75Q662,155.88,623.29,90.23l-26-44.1c-1.16-2-2.54-3.78-3.81-5.67Z"
                  id="Path-11"
                  fill={colors.orange}
                ></path>
                <path
                  d="M266.74,1h28.51c3.75,0,4.39,2.78,5.27,5.42q7.35,22,14.68,44,32.74,98.46,65.49,196.92c.72,2.18,1.61,4.3,2.5,6.64,3.32-1.4,3.36-4,4-6.12q14-41.72,27.81-83.5,25.8-77.64,51.61-155.28a23.76,23.76,0,0,1,1.86-5c.74-1.21,2.14-2.83,3.28-2.86,9.47-.23,18.94-.13,29.32-.13-.66,2.66-1,4.52-1.59,6.3q-46.8,140.45-93.65,280.89c-2,6-2,6-8.32,6h-25c-6.62,0-6.63,0-8.75-6.27l-32.63-96.34q-31-91.25-61.92-182.5C268.44,6.84,267.77,4.4,266.74,1Z"
                  id="Path-13"
                  fill={colors.orange}
                ></path>
                <path
                  d="M122.27,34.14a59.7,59.7,0,0,0-2.61,5.56q-19,53.21-38,106.44Q58.42,211.4,35.07,276.64c-2,5.68-2.07,5.7-8,5.72H.07c.88-2.91,1.43-5.13,2.22-7.26q36-97.6,72.14-195.24,13.72-37.1,27.45-74.19c2-5.52,2.2-5.62,8.18-5.64h26.79c5.24,0,5.32,0,7.22,5.16q15.35,41.25,30.62,82.53,25.17,68.06,50.3,136.13l19.57,53c.53,1.43.84,2.94,1.34,4.73a15.45,15.45,0,0,1-3.12.75c-8.34,0-16.67-.1-25,.1-3.67.09-5.29-1.61-6.4-4.83-4.28-12.36-8.72-24.67-13.12-37Q162.4,140.05,126.51,39.5c-.64-1.79-1.59-3.48-2.39-5.21Z"
                  id="Path-14"
                  fill={colors.orange}
                ></path>
                <path
                  d="M949.8,161.45V37c0-11.48-1.23-9.44-9.45-9.46-26.8-.07-53.6,0-80.4,0-1.59,0-3.32.34-4.72-.19-1.22-.46-2.89-1.88-2.93-2.93-.26-6.74-.25-13.5,0-20.23,0-1.06,1.71-2.48,2.93-2.94,1.4-.53,3.13-.22,4.72-.22H1068.4c8.12,0,8.13,0,8.14,8.24V23.54c0,2.72-1.25,4-4,4H982.61c-2.41,0-3.7,1.17-3.77,3.58q-.1,3.27-.09,6.55V286c0,8.8.3,8.36-8.55,8.33-5.36,0-10.72-.07-16.08,0-3,0-4.41-1.27-4.32-4.27.05-1.78,0-3.57,0-5.36Z"
                  id="Path-15"
                  fill={colors.orange}
                ></path>
              </g>
            </svg>
          </span>
        </h1>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ maxWidth: 240 }}>
          <CTA
            title="Download Now"
            onClick={() => {
              props.isMobile
                ? window.open("https://avntspace.app.link/download", "_blank")
                : history.push("/download");
            }}
          />
        </div>
      </div>
    </div>
  );
}

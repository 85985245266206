import React from "react";
import colors from "../../../constants/colors";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Media } from "@avntspace/avnt-lib-firebase";

export default function MediaCard(props: {
  media: Media;
  index: number;
  isMobile: boolean;
}) {
  const styles = {
    container: {
      display: "flex",
      flexDirection: props.isMobile ? ("column" as const) : ("row" as const),

      flex: 1,
      margin: 15,
      minHeight: 200,
    },
  };

  let url = props.media.url.includes("v=") ? props.media.url : "v=0";
  let embedId = url.split("v=")[1];
  let mediaDate = props.media.publishDate
    ? moment(new Date(props.media.publishDate).setHours(0, 0, 0, 0)).add(
        1,
        "day"
      )
    : moment();

  return (
    <div style={styles.container} key={props.index}>
      <div style={{ marginRight: 20, width: 500, minWidth: 500 }}>
        <iframe
          width="500"
          height="320"
          src={`https://www.youtube.com/embed/${embedId.split("&")[0]}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
      <div>
        <p
          style={{
            textAlign: "left",
            color: colors.orange,
            fontSize: 22,
            fontWeight: 700,
          }}
        >
          {props.media.title}
        </p>
        <p style={{ textAlign: "left" }}>{props.media.subtitle}</p>

        <p style={{ textAlign: "left", opacity: 0.7 }}>
          {mediaDate.isSame(moment(), "d")
            ? "Today"
            : mediaDate.from(new Date().setHours(0, 0, 0, 0))}
        </p>
      </div>
    </div>
  );
}

import { LimitedBrand } from "@avntspace/avnt-lib-firebase";
import React, { useState } from "react";
import { AsyncTypeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import colors from "../../constants/colors";

const BrandSearch = (props: {
  selectBrand: any;
  brands: LimitedBrand[];
  onQueryChange: any;
  withMenu?: boolean;
}) => {
  const [options, setOptions] = useState<LimitedBrand[]>([]);
  const filterBy = () => true;

  const handleSearch = async (query: string) => {
    props.onQueryChange(query);
    let newOptions = props.brands.filter((item) =>
      item.brandTitle.toLowerCase().includes(query.toLowerCase())
    );

    if (hideMenu) {
      setOptions([]);
    } else {
      setOptions(newOptions);
    }
  };

  const hideMenu = props.withMenu == false;

  return hideMenu ? (
    <AsyncTypeahead
      filterBy={filterBy}
      isLoading={false}
      minLength={1}
      onSearch={handleSearch}
      labelKey="brandTitle"
      options={options}
      emptyLabel={<div />}
      promptText={<div />}
      searchText={<div />}
      onChange={(vals: any) => props.selectBrand(vals)}
      renderMenu={(results, menuProps, state) => <div />}
      //@ts-ignore
      renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
        //@ts-ignore
        <input
          {...inputProps}
          ref={(input) => {
            //@ts-ignore
            inputRef(input);
            referenceElementRef(input);
          }}
          onBlur={() => props.onQueryChange("")}
          type="text"
          placeholder={"Search"}
          className={"search-input"}
          style={{
            paddingTop: 8,
            paddingBottom: 8,
            width: 250,
            display: "flex",
            flex: 1,
            border: "0px solid",
            fontSize: 16,
            color: "#EA9611",
            fontFamily: "Montserrat",
            backgroundColor: "transparent",
            borderBottom: "4px solid #EA9611",
          }}
        />
      )}
    />
  ) : (
    <AsyncTypeahead
      filterBy={filterBy}
      isLoading={false}
      minLength={1}
      onSearch={handleSearch}
      labelKey="brandTitle"
      options={options}
      emptyLabel={<div />}
      promptText={<div />}
      searchText={<div />}
      onChange={(vals: any) => props.selectBrand(vals)}
      renderMenuItemChildren={(option, props) => (
        <div
          {...props}
          style={{
            // marginLeft: -8,
            // backgroundColor: colors.black,
            color: colors.black,
            display: "flex",
          }}
        >
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              color: colors.black,
              fontSize: 14,
              padding: 12,
              textDecoration: "none",
              textDecorationColor: "transparent",
            }}
          >
            <div
              style={{
                borderColor: colors.black,
                borderWidth: 2,
                borderStyle: "solid",
                backgroundImage: "url(" + option.imageFile + ")",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                marginRight: 12,
                backgroundColor: colors.black,
                width: 40,
                minWidth: 40,
                height: 40,
                maxWidth: 40,
                minHeight: 40,
                maxHeight: 40,

                borderRadius: 40 / 2,
                overflow: "hidden",
              }}
            ></div>
            {option.brandTitle}
          </span>
        </div>
      )}
      //@ts-ignore
      renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
        //@ts-ignore
        <input
          {...inputProps}
          ref={(input) => {
            //@ts-ignore
            inputRef(input);
            referenceElementRef(input);
          }}
          onBlur={() => props.onQueryChange("")}
          type="text"
          placeholder={"Search"}
          className={"search-input"}
          style={{
            paddingTop: 8,
            paddingBottom: 8,
            width: 250,
            display: "flex",
            flex: 1,
            border: "0px solid",
            fontSize: 16,
            color: "#EA9611",
            fontFamily: "Montserrat",
            backgroundColor: "transparent",
            borderBottom: "4px solid #EA9611",
          }}
        />
      )}
    />
  );
};

export default BrandSearch;

import React from "react";
import colors from "../../constants/colors";

export default function TwoItemSelect(props: {
  itemOne: string;
  itemTwo: string;
  selected: string;
  onSelectOne: any;
  onSelectTwo: any;
}) {
  return (
    <div style={{ width: 300 }}>
      <div
        className={"segmented-control"}
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "flex-end",
          color: colors.orange,
        }}
      >
        <input
          type="radio"
          readOnly={true}
          id="item-1"
          checked={props.selected === props.itemOne}
        ></input>
        <input
          readOnly={true}
          type="radio"
          id="item-2"
          checked={props.selected === props.itemTwo}
        ></input>

        <label
          htmlFor="item-1"
          data-value={props.itemOne}
          style={{
            cursor: "pointer",
          }}
          onClick={props.onSelectOne}
        >
          {props.itemOne}
        </label>

        <label
          htmlFor="item-2"
          data-value={props.itemTwo}
          style={{
            cursor: "pointer",
          }}
          onClick={props.onSelectTwo}
        >
          {props.itemTwo}
        </label>
      </div>
    </div>
  );
}

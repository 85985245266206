import React from "react";
import { Link } from "react-router-dom";
import colors from "../constants/colors";
import { useMe } from "@avntspace/avnt-lib-firebase";
import LoginModal from "../components/modals/login-modal";
import { useFirebase } from "react-redux-firebase";
import Select from "react-select";
import { functionsRef } from "../firebase/store";
import StripeService from "../services/stripeService";

export default function Header({ isMobile }) {
  const styles = {
    link: {
      marginRight: 14,
      marginLeft: 14,
      color: "#fff",
    },
    mobileContainer: {
      position: "absolute" as const,
      top: 0,
      left: 0,
      width: "100vw",
      background:
        "linear-gradient(0deg, rgba(14,17,17,0) 0%, rgba(14,17,17,0.5) 40%, rgba(14,17,17,1) 100%)",
      borderBottomColor: colors.orange,
      borderBottomWidth: 2,
      borderBottomStyle: "solid" as const,
    },
    webContainer: {
      zIndex: 101,
      position: "absolute" as const,
      top: 0,
      left: 0,
      width: "100vw",
      background:
        "linear-gradient(0deg, rgba(14,17,17,0) 0%, rgba(14,17,17,0.5) 40%, rgba(14,17,17,1) 100%)",
    },
    webRow: {
      display: "flex",
      paddingLeft: "10vw",
      paddingRight: "10vw",
      justifyContent: "center" as const,
    },
    leftRow: {
      display: "flex",
      flexDirection: "row" as const,
      paddingTop: 30,
      justifyContent: "space-between" as const,
      width: "80vw",
      borderBottomColor: colors.orange,
      borderBottomWidth: 2,
      borderBottomStyle: "solid" as const,
    },
    noDecoration: { textDecoration: "none" },
    logoContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    logo: {
      marginRight: 14,
      marginLeft: 14,
      color: colors.orange,
      fontSize: 30,
      textAlign: "center" as const,
    },
  };

  let modal: any;
  const me = useMe();
  const firebase = useFirebase();

  const hideModal = () => {
    modal.hide();
  };

  const showModal = () => {
    modal.show();
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      minWidth: 120,
      width: state.selectProps.width,
      color: state.selectProps.menuColor,
    }),
    control: () => ({
      display: "flex",
      marginRight: -14,
      marginLeft: 14,
    }),
    placeholder: () => ({
      color: colors.orange,
    }),
    dropdownIndicator: () => ({ opacity: 0 }),
    indicatorSeparator: () => ({ opacity: 0 }),
  };

  if (isMobile) {
    return (
      <div style={styles.mobileContainer}>
        <Link to="/" style={styles.noDecoration}>
          <p style={styles.logo}>AVNT</p>
        </Link>
      </div>
    );
  }

  const getDropdownOptions = () => {
    //@ts-ignore
    if (me.isCreator && me.subscriptionId && me.subscriptionKind == "stripe") {
      return [
        { value: "manage_subscription", label: "manage subscription" },
        { value: "log_out", label: "log out" },
      ];
    }
    return [{ value: "log out", label: "log out" }];
  };

  const openManageSubscription = async () => {
    //@ts-ignore
    const res = await StripeService.getCustomerPortalURL(me.subscriptionId);

    if (res && res.url) {
      window.open(res.url);
    }
  };

  return (
    <div style={styles.webContainer}>
      <div style={styles.webRow}>
        <div style={styles.leftRow}>
          <div
            style={{
              flex: 2,
              flexDirection: "row",
              display: "flex",
            }}
          >
            <Link to="/shop" style={styles.noDecoration}>
              <p style={styles.link}>Shop</p>
            </Link>
            <Link to="/discover" style={styles.noDecoration}>
              <p style={styles.link}>Discover</p>
            </Link>
            <Link to="/media" style={styles.noDecoration}>
              <p style={styles.link}>Media</p>
            </Link>
            <Link to="/download" style={styles.noDecoration}>
              <p style={styles.link}>App</p>
            </Link>
          </div>

          <div
            style={{
              flex: 1,
            }}
          >
            <div style={styles.logoContainer}>
              <Link to="/" style={styles.noDecoration}>
                <p style={styles.logo}>AVNT</p>
              </Link>
            </div>
          </div>

          <div
            style={{
              flex: 2,
              flexDirection: "row",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
           <a href="https://services.avnt.space/" style={styles.noDecoration}>
              <p style={styles.link}>AVNT Services</p>
            </a>
            <a href="https://avnt.helpcenter.io/" style={styles.noDecoration}>
              <p style={styles.link}>Help</p>
            </a>

            {me && me.id && me.username ? (
              <Select
                isClearable={false}
                isSearchable={false}
                isMulti={false}
                options={getDropdownOptions()}
                styles={customStyles}
                menuColor={colors.orange}
                onChange={(e) => {
                  if (e.value == "manage_subscription") {
                    openManageSubscription();
                  } else {
                    firebase.logout();
                  }
                }}
                placeholder={me.username}
                value={null}
              />
            ) : (
              <div style={{ cursor: "pointer" }} onClick={showModal}>
                <p style={styles.link}>Sign In</p>
              </div>
            )}
          </div>
        </div>
        <LoginModal
          setModal={(ref) => {
            if (ref) {
              modal = ref;
            }
          }}
          hideModal={hideModal}
          isMobile={isMobile}
        />
      </div>
    </div>
  );
}

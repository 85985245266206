import React, { useState } from "react";
import colors from "../../constants/colors";
import { useMe } from "@avntspace/avnt-lib-firebase";
import TwoItemSelect from "../buttons/two-item-select";
import { CTA } from "../buttons/buttons";
import { useFirebase, useFirestore } from "react-redux-firebase";
//@ts-ignore
import { OutlineModal } from "boron-15";

export default function LoginModal({
  isMobile,
  hideModal,
  setModal,
  onModalHide,
}: {
  isMobile: boolean;
  hideModal: any;
  setModal: any;
  onModalHide?: any;
}) {
  const [loginIndex, setLoginIndex] = useState(0);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorString, setErrorString] = useState("");
  const [resettingPass, setResettingPass] = useState(false);
  const firebase = useFirebase();
  const firestore = useFirestore();

  const reset = (err: string) => {
    setEmail("");
    setUsername("");
    setPassword("");
    setErrorString(err);
  };

  const empty = () => {
    return username === "" && email === "";
  };

  const send = async () => {
    if (resettingPass) {
      try {
        await firebase.resetPassword(email);
        setResettingPass(false);
        reset(`Password reset email sent to ${email}`);
      } catch (err) {
        reset(`There is no account associated with the email ${email}`);
      }

      setTimeout(() => {
        setErrorString("");
      }, 5000);
    } else if (loginIndex === 0) {
      createAccount();
    } else {
      //Log In
      if (email.includes("@")) {
        try {
          await firebase.login({
            email: email,
            password: password,
          });
          setErrorString("");
          reset("");
          hideModal();
        } catch (error) {
          reset("Wrong password. Please try again.");
        }
      } else {
        usernameLogin();
      }
    }
  };

  const usernameLogin = async () => {
    firestore
      .collection("users")
      .where("username", "==", email)
      .get()
      .then(async (snapshot) => {
        let items = [];
        snapshot.forEach((item) => {
          items.push({ ...item.data(), id: item.id });
        });
        if (items.length === 1) {
          try {
            await firebase.login({
              email: items[0].email,
              password: password,
            });
            reset("");
            hideModal();
          } catch (error) {
            reset(error.message);
          }
        } else {
          reset("We could not find that username.");
        }
      });
  };

  const createAccount = async () => {
    if (username.length < 3) {
      reset("Username is required.");
    } else {
      firestore
        .collection("users")
        .where("username", "==", username)
        .get()
        .then(async (snapshot) => {
          let items = [];
          snapshot.forEach((item) => {
            items.push({ ...item.data(), id: item.id });
          });

          if (items.length > 0) {
            reset("That username is already taken.");
          } else {
            let profileData = {
              admin: false,
              mode: "dark",
              updatedAt: new Date(),
              username: username,
              createdAt: new Date(),
              email: email,
              isCreator: false,
              bio: "",
              realName: "",
              instagram: "",
              twitter: "",
              location: "",
              imageFile: null,
              website: "",
              timezone: null,
              brandId: null,
              applicationTime: null,
            };
            try {
              await firebase.createUser({ email, password }, profileData);
              reset("");
              hideModal();
            } catch (error) {
              reset(error.message);
            }
          }
        });
    }
  };

  if (isMobile) {
    return <div />;
  }

  return (
    <OutlineModal
      ref={setModal}
      contentStyle={{
        backgroundColor: colors.black,
        borderColor: colors.orange,
        borderWidth: 5,
        borderRadius: 8,
        borderStyle: "solid",
      }}
      onHide={() => {
        if (onModalHide) {
          onModalHide();
        }
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: isMobile ? 10 : 20,
          paddingRight: isMobile ? 10 : 20,
          paddingTop: 50,
          alignItems: "center",
        }}
      >
        {resettingPass ? (
          <div />
        ) : (
          <TwoItemSelect
            itemOne={"sign up"}
            itemTwo={"log in"}
            onSelectOne={() => setLoginIndex(0)}
            onSelectTwo={() => setLoginIndex(1)}
            selected={loginIndex === 0 ? "sign up" : "log in"}
          />
        )}
        {errorString !== "" && empty() ? (
          <p
            style={{
              textAlign: "center",
              color: "red",
              marginTop: 8,
            }}
          >
            {errorString}
          </p>
        ) : (
          <div />
        )}
        <input
          type="text"
          placeholder={loginIndex === 0 ? "email" : "email/username"}
          style={{
            borderBottomWidth: 2,
            borderBottomStyle: "solid",
            borderBottomColor: colors.orange,
            color: colors.white,
            backgroundColor: "transparent",
            marginTop: 20,
            padding: 8,
            marginBottom: 10,
            width: isMobile ? 200 : 350,
          }}
          value={email}
          onChange={(evt) => {
            let input = evt.target.value || "";
            // .replace(/[^a-zA-Z0-9_]/g, "");
            setEmail(input.toLowerCase().trim());
          }}
        />
        {loginIndex === 0 && !resettingPass ? (
          <input
            type="text"
            placeholder="username"
            style={{
              borderBottomWidth: 2,
              borderBottomStyle: "solid",
              borderBottomColor: colors.orange,
              color: colors.white,
              backgroundColor: "transparent",

              padding: 8,
              marginBottom: 10,
              width: isMobile ? 200 : 350,
            }}
            value={username}
            onChange={(evt) => {
              let input = (evt.target.value || "").replace(
                /[^a-zA-Z0-9_]/g,
                ""
              );
              setUsername(input.toLowerCase().trim());
            }}
          />
        ) : (
          <div />
        )}
        {resettingPass ? (
          <div />
        ) : (
          <input
            type="password"
            placeholder="password"
            style={{
              borderBottomWidth: 2,
              borderBottomStyle: "solid",
              borderBottomColor: colors.orange,
              color: colors.white,
              backgroundColor: "transparent",

              padding: 8,
              marginBottom: 10,
              width: isMobile ? 200 : 350,
            }}
            value={password}
            onChange={(evt) => setPassword(evt.target.value)}
          />
        )}
        <div style={{ height: 50 }}></div>

        <CTA
          title={
            resettingPass
              ? "RESET PASSWORD"
              : loginIndex === 0
              ? "SIGN UP"
              : "LOG IN"
          }
          onClick={send}
        />
        <div
          onClick={() => setResettingPass(!resettingPass)}
          style={{ cursor: "pointer" }}
        >
          <p
            style={{
              marginTop: -30,
              marginBottom: 30,
              opacity: 0.7,
              fontSize: 14,
            }}
          >
            {resettingPass ? "Cancel" : `Forgot your password?`}
          </p>
        </div>
      </div>
    </OutlineModal>
  );
}

import React, { useState } from "react";
import { Tool } from "@avntspace/avnt-lib-firebase";
import colors from "../../constants/colors";
import ReactHtmlParser from "react-html-parser";
import { CTA, SmallCTA } from "../buttons/buttons";

export default function ToolCard(props: {
  release: Tool;
  index: number;
  isMobile?: boolean;
}) {
  const CARD_WIDTH = props.isMobile ? 350 : 450;
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column" as const,
      width: props.isMobile ? "90vw" : "75vw",
      maxWidth: props.isMobile ? "90vw" : 900,
      flex: 1,
      margin: props.isMobile ? 0 : 15,
      marginBottom: 15,
      borderColor: colors.orange,
      borderWidth: 2,
      borderStyle: "solid",
      borderRadius: 12,
      padding: 12,
      paddingBottom: 8,
      paddingTop: 8,
      boxShadow: "3px 3px 16px rgba(234, 150, 17, 0.8)",
    },
    imageRow: {
      flex: 1,

      display: "flex",
      flexDirection: "row" as const,
      paddingBottom: 8,
      marginLeft: 12,
      marginTop: 12,
    },
    textCol: {
      flex: 1,

      display: "flex",
      flexDirection: "column" as const,
      padding: 12,
      paddingBottom: 0,
      paddingTop: 0,
      alignItems: "center",
    },
  };

  const [readAll, setReadAll] = useState(false);

  const visitReleaseURL = async (release: Tool) => {
    window.open(release.url, "_blank");
  };

  if (props.isMobile) {
    return (
      <div style={styles.container} key={props.index}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 12,
          }}
        >
          <div
            style={{
              backgroundImage: "url(" + props.release.mainImage.src + ")",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
              width: CARD_WIDTH / 2 - 40,
              minWidth: CARD_WIDTH / 2 - 40,
              height: CARD_WIDTH / 2 - 40,
              borderRadius: 12,
              overflow: "hidden",
            }}
          ></div>
          <p
            style={{
              textAlign: "center",
              color: colors.orange,
              fontSize: 20,
              fontWeight: 700,
              margin: "auto",
              padding: 5,
            }}
          >
            {props.release.title.toUpperCase()}
          </p>
        </div>

        <div style={{ ...styles.textCol, padding: 8 }}>
          <p
            style={
              readAll
                ? { textAlign: "center", fontSize: 14 }
                : {
                    textAlign: "center",
                    fontSize: 14,
                    lineHeight: "16px",
                    maxHeight: 16 * 5,
                  }
            }
            className={readAll ? "" : "two-lines"}
          >
            {ReactHtmlParser(props.release.description)}
          </p>
          <p
            onClick={() => setReadAll(!readAll)}
            style={{
              width: "100%",
              textAlign: "right",
              color: colors.orange,
              fontSize: 14,
            }}
          >
            {readAll ? "READ LESS" : `READ MORE`}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          <p style={{ textAlign: "center", marginBottom: 8 }}>
            {props.release.price}
          </p>
          <SmallCTA
            title={
              //@ts-ignore
              props.release.purchaseButtonText
                ? //@ts-ignore
                  props.release.purchaseButtonText
                : "Buy Now"
            }
            onClick={() => visitReleaseURL(props.release)}
          />
        </div>
      </div>
    );
  }
  return (
    <div style={styles.container} key={props.index}>
      <div style={styles.imageRow}>
        <div style={{ marginBottom: -40 }}>
          <div
            style={{
              backgroundImage: "url(" + props.release.mainImage.src + ")",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
              width: CARD_WIDTH / 2 - 30,
              minWidth: CARD_WIDTH / 2 - 30,
              height: CARD_WIDTH / 2 - 30,
              borderRadius: 12,
              overflow: "hidden",
            }}
          ></div>
          <div style={{ marginTop: 10 }}>
            <p style={{ textAlign: "center", marginBottom: 8 }}>
              {props.release.price}
            </p>
            <CTA
              title={
                //@ts-ignore
                props.release.purchaseButtonText
                  ? //@ts-ignore
                    props.release.purchaseButtonText
                  : "Buy Now"
              }
              onClick={() => visitReleaseURL(props.release)}
            />
          </div>
        </div>

        <div style={styles.textCol}>
          <p
            style={{
              textAlign: "center",
              color: colors.orange,
              fontSize: 22,
              fontWeight: 700,
              marginTop: 8,
              marginBottom: 8,
            }}
          >
            {props.release.title.toUpperCase()}
          </p>
          <p
            style={{ textAlign: "center" }}
            className={readAll ? "" : "two-lines"}
          >
            {ReactHtmlParser(props.release.description)}
          </p>
          <p
            onClick={() => setReadAll(!readAll)}
            style={{
              width: "100%",
              textAlign: "right",
              color: colors.orange,
            }}
          >
            {readAll ? "READ LESS" : `READ MORE`}
          </p>
        </div>
      </div>
    </div>
  );
}

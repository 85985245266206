import React, { useState } from "react";

import colors from "../../../constants/colors";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { PaypalCheckout } from "./paypal-checkout";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeCheckout from "./stripe-checkout";
import { AiFillCreditCard } from "react-icons/ai";
import { DotLoader } from "react-spinners";
import { Brand, useMe } from "@avntspace/avnt-lib-firebase";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { defaultAnalytics } from "../../../firebase/store";
import { PAYPAL_CLIENT_ID, STRIPE_KEY } from "../../../setup/FIREBASE_CONFIG";

const stripePromise = loadStripe(STRIPE_KEY);

export default function Checkout({ plan, isMobile }) {
  const styles = {
    subscribeButton: {
      width: 300,
      height: 45,
      borderRadius: 4,
      marginBottom: 12,
      backgroundColor: colors.orange,
      border: "none",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
    },
    container: isMobile
      ? {
          display: "flex",
          marginTop: 30,
          flexDirection: "column" as const,
          alignItems: "center",
          width: "90vw",
        }
      : {
          display: "flex",
          marginTop: 30,
          flexDirection: "column" as const,
          alignItems: "flex-end",
          width: 700,
        },
  };

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [useStripe, setUseStripe] = useState(false);
  const me = useMe();
  const firestore = useFirestore();

  const createBrand = async () => {
    await firestore
      .collection("users")
      .doc(me.id)
      .update({ purchasedPlans: firestore.FieldValue.arrayUnion(plan.id) });

    setLoading(false);
    history.push("/club");
  };

  return (
    <div style={styles.container}>
      {useStripe ? (
        <div>
          <Elements stripe={stripePromise}>
            <StripeCheckout
              plan={plan}
              isMobile={isMobile}
              createBrand={createBrand}
              setLoading={setLoading}
              loading={loading}
            />
          </Elements>
          <div
            style={{
              justifyContent: "flex-end",
              display: "flex",
              cursor: "pointer",
              marginTop: 12,
            }}
            onClick={() => setUseStripe(false)}
          >
            <p style={{ color: "#788ca0" }}>cancel</p>
          </div>
        </div>
      ) : (
        <div>
          <button
            style={styles.subscribeButton}
            onClick={() => setUseStripe(true)}
          >
            <AiFillCreditCard
              color={colors.black}
              style={{ fontSize: 24, marginRight: 6 }}
            />
            Subscribe
          </button>
          <div style={{ width: 300 }}>
            <PayPalScriptProvider
              options={{
                "client-id": PAYPAL_CLIENT_ID,
                intent: "subscription",
                // debug: true,
                vault: true,
              }}
            >
              <PaypalCheckout
                plan={plan}
                createBrand={createBrand}
                setLoading={setLoading}
              />
            </PayPalScriptProvider>
          </div>
        </div>
      )}
      {loading && !useStripe ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <DotLoader color={colors.orange} loading={true} size={50} />
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}

import React, { Component, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import colors from "../constants/colors";
import { slide as SlideMenu } from "react-burger-menu";
import { GiHamburgerMenu } from "react-icons/gi";

export default function Menu() {
  const [open, setOpen] = useState(false);
  const history = useHistory();

  return (
    <SlideMenu
      id={"slide"}
      right={true}
      customBurgerIcon={
        <div style={{ backgroundColor: "green" }}>
          <GiHamburgerMenu
            color={colors.white}
            style={{ width: 24, height: 24 }}
          />
        </div>
      }
      isOpen={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <div style={{ paddingTop: 20 }}>
        <div
          onClick={() => {
            history.push("/");
            setOpen(false);
          }}
          style={{ textDecoration: "none" }}
        >
          <p
            style={{
              ...styles.link,
              color: colors.black,
              fontSize: 30,
              textAlign: "center",
            }}
          >
            AVNT
          </p>
        </div>
        <div
          onClick={() => {
            history.push("/shop");
            setOpen(false);
          }}
          style={{ textDecoration: "none" }}
        >
          <p style={styles.link}>Shop</p>
        </div>
        <div
          onClick={() => {
            history.push("/discover");
            setOpen(false);
          }}
          style={{ textDecoration: "none" }}
        >
          <p style={styles.link}>Discover</p>
        </div>
        <div
          onClick={() => {
            history.push("/media");
            setOpen(false);
          }}
          style={{ textDecoration: "none" }}
        >
          <p style={styles.link}>Media</p>
        </div>
        <a href="https://services.avnt.space/">
          <p style={styles.link}>AVNT Services</p>
        </a>
        <a href="https://avnt.helpcenter.io/">
          <p style={styles.link}>Help</p>
        </a>

        <a href="https://avntspace.app.link/download">
          <p style={styles.link}>App</p>
        </a>
      </div>
    </SlideMenu>
  );
}

let styles = {
  link: {
    marginRight: 14,
    marginLeft: 14,
    marginBottom: 14,
    color: "#fff",
  },
};

import React from "react";
import { LimitedBrand } from "@avntspace/avnt-lib-firebase";
import shirt from "../../assets/shirt.png";
import { OverlayTrigger, Popover } from "react-bootstrap";
import BrandCircle from "./brand-circle";

export default function MapMarker(props: {
  brand: LimitedBrand;
  lat: number;
  lng: number;
}) {
  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      key={props.brand.id}
      placement={"top"}
      overlay={
        <Popover
          id={`popover-${props.brand.id}`}
          style={{
            padding: 0,
            backgroundColor: "transparent",
            border: "none",
            boxShadow: "none",
          }}
        >
          <Popover.Content
            style={{
              padding: 0,
              margin: 0,
              alignItems: "center",
              flexDirection: "column",
              display: "flex",
            }}
          >
            <BrandCircle brand={props.brand} index={0} skipTitle={true} />
            <div
              style={{
                marginTop: -4,
                width: 0,
                height: 0,
                borderLeft: "20px solid transparent",
                borderRight: "20px solid transparent",

                borderTop: "20px solid #0e1111",
              }}
            />
          </Popover.Content>
        </Popover>
      }
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
        onClick={() => {
          window.open(
            props.brand.brandWebsite
              ? props.brand.brandWebsite.includes("http")
                ? props.brand.brandWebsite
                : `http://${props.brand.brandWebsite}`
              : "http://avnt.space",
            "_blank"
          );
        }}
      >
        <div
          style={{
            width: 30,
            height: 30,

            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            backgroundImage: "url(" + shirt + ")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",

            overflow: "hidden",
          }}
        ></div>
      </div>
    </OverlayTrigger>
  );
}

import { useMe, usePackages } from "@avntspace/avnt-lib-firebase";
import React, { useState } from "react";
import { useFirestoreConnect } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import "react-toggle/style.css"; // for ES6 modules
import { CTA } from "../../components/buttons/buttons";
import ReadonlyMap from "../../components/map/readonly-map";
import TestimonialsGrid from "../../components/testimonials/testimonial-grid";
import { PageSubtitle, PageTitle } from "../../components/text";
import colors from "../../constants/colors";
import { BRAND_DATA } from "../become-creator-page/become-creator-page";

export default function TestimonialPage({ isMobile }) {
  const styles = {
    headerContainer: {
      display: "flex",
      flexDirection: "column" as const,

      marginTop: -70,
      alignItems: "center",
      minHeight: "100vh",
      maxWidth: "100vw",
    },
    orangeWorksContainer: {
      height: 200,
      marginTop: 40,
      display: "flex",
      flexDirection: "row" as const,
      marginLeft: 100,
      marginRight: 100,
      marginBottom: 60,
    },
    orangeWorks: {
      display: "flex",
      padding: 20,
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      borderWidth: 3,
      borderStyle: "solid",
      borderColor: colors.orange,
      borderRadius: 8,
      marginLeft: 10,
      textAlign: "center" as const,
      marginRight: 10,
    },
    gradient: {
      height: "70vh",

      background:
        "linear-gradient(0deg, rgba(14,17,17,1) 5%, rgba(14,17,17,0.9) 34%, rgba(14,17,17,0.3) 100%)",
      width: "100vw",
      marginTop: "-70vh",
      zIndex: 99,
      display: "flex",
      flexDirection: "column" as const,
      justifyContent: "flex-end",
    },
  };

  const me = useMe();
  const brandData = BRAND_DATA;

  const packages = usePackages();
  const history = useHistory();
  const [showYearly, setShowYearly] = useState(false);

  useFirestoreConnect([
    {
      collection: "packages",
    },
  ]);

  return (
    <div style={styles.headerContainer}>
      <ReadonlyMap brands={brandData} height={"70vh"} width={"100vw"} />

      <div style={styles.gradient}></div>

      <div
        style={{
          marginTop: -130,
          zIndex: 100,
          marginBottom: 100,
          paddingLeft: isMobile ? "5vw" : "15vw",
          paddingRight: isMobile ? "5vw" : "15vw",
        }}
      >
        <div style={{ marginBottom: 20 }}>
          <PageTitle
            isMobile={isMobile}
            title="See what Creators are saying:"
          />
        </div>

        <TestimonialsGrid kind={"creator"} />
      </div>
    </div>
  );
}

import React from "react";
import { LimitedBrand } from "@avntspace/avnt-lib-firebase";
import GoogleMapReact from "google-map-react";
import colors from "../../constants/colors";
import MapMarker from "./map-marker";

export default function Map(props: {
  brands: LimitedBrand[];
  height?: any;
  width?: any;
  hideBorder?: boolean;
  isMobile?: boolean;
}) {
  const styles = {
    mapContainer: {
      height: props.height ? props.height : props.isMobile ? "65vh" : "80vh",
      width: props.width ? props.width : props.isMobile ? "100vw" : "90vw",
      borderColor: colors.orange,
      borderWidth: props.hideBorder || props.isMobile ? 0 : 4,
      borderStyle: "solid",
      borderRadius: props.hideBorder || props.isMobile ? 0 : 10,
      overflow: "hidden",
      backgroundColor: colors.black,
    },
  };

  return (
    <div style={styles.mapContainer}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyACIpmWZsX2yUqawS4EWpYfYWKVi_6kCZc" }}
        defaultCenter={{
          lat: 39.3,
          lng: -97.67,
        }}
        defaultZoom={4.8}
      >
        {(props.brands || []).map((item: LimitedBrand, index: number) => (
          <MapMarker
            lat={item.geoPoint.latitude}
            lng={item.geoPoint.longitude}
            brand={item}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
}

import React from "react";
import {
  PayPalButtons,
  FUNDING,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { Package, useMe } from "@avntspace/avnt-lib-firebase";
import { useFirestore } from "react-redux-firebase";

export function PaypalCheckout(props: {
  plan: Package;
  createBrand: any;
  setLoading: any;
}) {
  const [{ options }, dispatch] = usePayPalScriptReducer();
  const me = useMe();
  const firestore = useFirestore();

  const onPurchaseComplete = async (purchase: any) => {
    props.setLoading(true);
    let purchaseObj = {
      userId: me.id,
      billingToken: purchase.billingToken || "",
      facilitatorAccessToken: purchase.facilitatorAccessToken || "",
      orderID: purchase.orderID || "",
      subscriptionID: purchase.subscriptionID || "",
      purchaseName: props.plan.name || "",
      amount: props.plan.price || "",
      timeCreated: new Date(),
    };

    await firestore.collection("paypal").add(purchaseObj);

    await firestore.collection("users").doc(me.id).update({
      subscriptionId: purchase.subscriptionID,
      subscriptionKind: "paypal",
    });

    // tara here

    let customer = await firestore.collection("customers").doc(me.id).get();
    if (customer.exists) {
      await firestore.collection("customers").doc(me.id).update({
        subscription: purchase.subscriptionID,
        subscriptionKind: "paypal",
      });
    } else {
      await firestore.collection("customers").doc(me.id).set({
        subscription: purchase.subscriptionID,
        subscriptionKind: "paypal",
      });
    }

    props.createBrand();
  };

  const buttonSubscriptionOptions = {
    createSubscription: function (data, actions) {
      console.log("create subscription");
      return actions.subscription.create({
        plan_id: props.plan.paypalId,
      });
    },
    onApprove: function (data, actions) {
      onPurchaseComplete(data);
    },
  };

  const buttonOptions =
    options.intent === "subscription" ? buttonSubscriptionOptions : {};

  return (
    <div style={{ minWidth: 300, flex: 1 }}>
      <PayPalButtons fundingSource={FUNDING.PAYPAL} {...buttonOptions} />
    </div>
  );
}

import React from "react";
import { LimitedBrand } from "@avntspace/avnt-lib-firebase";
import colors from "../../constants/colors";

export default function BrandCircle(props: {
  brand: LimitedBrand;
  index: number;
  skipTitle?: boolean;
  titleColor?: string;
  small?: boolean;
}) {
  const CIRCLE_SIZE = props.small ? 70 : 100;
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column" as const,
      margin: props.small ? 5 : props.skipTitle ? 0 : 15,
      padding: props.small ? 5 : props.skipTitle ? 0 : 12,
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    },
  };

  return (
    <div
      style={styles.container}
      key={props.index}
      onClick={() =>
        window.open(
          props.brand.brandWebsite
            ? props.brand.brandWebsite.includes("http")
              ? props.brand.brandWebsite
              : `http://${props.brand.brandWebsite}`
            : "http://avnt.space",
          "_blank"
        )
      }
    >
      {props.skipTitle ? (
        <p
          style={{
            textAlign: "center",
            paddingTop: 12,
            fontSize: props.small ? 14 : 18,
            fontWeight: 700,
            color: colors.black,
          }}
        >
          {props.brand.brandTitle}
        </p>
      ) : (
        <div />
      )}
      <div
        style={{
          backgroundImage: "url(" + props.brand.imageFile + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundColor: props.titleColor ? props.titleColor : colors.black,
          width: CIRCLE_SIZE,
          minWidth: CIRCLE_SIZE,
          height: CIRCLE_SIZE,

          borderRadius: CIRCLE_SIZE / 2,
          overflow: "hidden",
          borderWidth: props.skipTitle ? 2 : 0,
          borderStyle: "solid",
          borderColor: colors.black,
        }}
      ></div>
      {props.skipTitle ? (
        <div />
      ) : (
        <p
          style={{
            textAlign: "center",
            paddingTop: 12,
            fontWeight: "bold",
            fontSize: props.small ? 14 : 18,
            color: props.titleColor ? props.titleColor : colors.orange,
          }}
        >
          {props.brand.brandTitle}
        </p>
      )}
    </div>
  );
}

import React from "react";
import colors from "../constants/colors";

const styles = {
  headerTitle: {
    zIndex: 99,
    color: "#eef5f8",
    textAlign: "center" as const,
    marginBottom: 12,
  },
  headerSubtitle: {
    fontSize: 24,
    color: "#788ca0",
    textAlign: "center" as const,
    marginBottom: 30,
  },
  headerSubtitleBright: {
    fontSize: 20,
    color: "#eef5f8",
    textAlign: "center" as const,
    marginBottom: 10,
  },
  orangeTitle: {
    fontSize: 24,
    color: colors.orange,
    textAlign: "center" as const,
  },
};

export function PageTitle(props: { title: string; isMobile?: boolean }) {
  return (
    <p style={{ ...styles.headerTitle, fontSize: props.isMobile ? 30 : 40 }}>
      {props.title}
    </p>
  );
}

export function PageSubtitle(props: { title: string; isMobile?: boolean }) {
  return (
    <p style={{ ...styles.headerSubtitle, fontSize: props.isMobile ? 20 : 24 }}>
      {props.title}
    </p>
  );
}

export function PageSubtitleNoMargin({ title }) {
  return <p style={{ ...styles.headerSubtitle, margin: 0 }}>{title}</p>;
}

export function PageSubtitleBright({ title }) {
  return <p style={styles.headerSubtitleBright}>{title}</p>;
}

export function OrangeTitle({ title }) {
  return <p style={styles.orangeTitle}>{title}</p>;
}

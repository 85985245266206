import { STRIPE_SECRET } from "../setup/FIREBASE_CONFIG";
// import env from "react-dotenv";

export default class StripeService {
  static createStripeHeaders = (method?: string) => {
    return {
      method: method || "post",
      headers: {
        "Content-type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${STRIPE_SECRET}`,
      },
    };
  };

  static getCustomerPortalURL = (customerId: string) => {
    return fetch(
      `https://api.stripe.com/v1/billing_portal/sessions?customer=${customerId}`,
      StripeService.createStripeHeaders()
    ).then((response) => {
      return response.json();
    });
  };

  static fetchStripeCustomer = (email: string) => {
    return fetch(
      `https://api.stripe.com/v1/customers?email=${email}`,
      StripeService.createStripeHeaders("get")
    ).then((response) => {
      return response.json();
    });
  };

  static createStripeCustomer = (email: string) => {
    return fetch(
      `https://api.stripe.com/v1/customers?email=${email}`,
      StripeService.createStripeHeaders()
    ).then((response) => {
      return response.json();
    });
  };

  static addSourceToCustomer = (
    customerId: string,
    paymentMethodId: any
  ): Promise<any> => {
    return fetch(
      `https://api.stripe.com/v1/payment_methods/${paymentMethodId}/attach?customer=${customerId}`,
      StripeService.createStripeHeaders()
    ).then((response) => {
      return response.json();
    });
  };

  static setDefaultPaymentMethod = (
    customerId: string,
    paymentMethodId: string
  ) => {
    return fetch(
      `https://api.stripe.com/v1/customers/${customerId}?invoice_settings[default_payment_method]=${paymentMethodId}`,
      StripeService.createStripeHeaders()
    ).then((response) => {
      return response.json();
    });
  };

  static createSubscription = (priceId: string, customerId: string) => {
    return fetch(
      `https://api.stripe.com/v1/subscriptions?customer=${customerId}&items[0][price]=${priceId}`,
      StripeService.createStripeHeaders()
    ).then((response) => {
      return response.json();
    });
  };
}

import { Package, useMe } from "@avntspace/avnt-lib-firebase";
import React from "react";
import colors from "../../../constants/colors";
import ReactHtmlParser from "react-html-parser";
import {
  CTA,
  OutlineCTA,
  SmallDarkCTA,
} from "../../../components/buttons/buttons";
import { useHistory } from "react-router-dom";
import logo from "../../../assets/logo-high.png";

export default function PackageItem(props: {
  package: any; //Package
  index: number;
  isMobile: boolean;
}) {
  const me = useMe();
  const history = useHistory();
  const styles = {
    logo: {
      width: 180,
      height: 180,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundImage: "url(" + logo + ")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "contain",
    },
  };

  const didPurchase = React.useMemo(() => {
    // @ts-ignore
    if (((me as any).purchasedPlans || []).includes(props.package.id)) {
      return true;
    }
    return false;
  }, [me, props.package]);

  return (
    <div>
      {didPurchase ? (
        <p
          style={{
            textAlign: "center",
            fontSize: 20,
            fontWeight: "bold",
            color: colors.orange,
          }}
        >
          YOUR PLAN
        </p>
      ) : (
        <div />
      )}
      <div
        key={props.index}
        style={{
          marginBottom: 40,
          minWidth: props.isMobile ? 340 : 500,
          maxWidth: props.isMobile ? 340 : 500,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flex: 1,
          borderColor: colors.orange,
          borderWidth: didPurchase ? 2 : 0,
          borderStyle: "solid",
          borderRadius: 12,
          marginLeft: 15,
          marginRight: 15,
          padding: 15,
          textAlign: "center",
        }}
      >
        <div style={styles.logo} />
        <p
          style={{
            marginBottom: 12,
            color: colors.orange,
            fontSize: 20,
            fontWeight: "bold",
          }}
        >
          {props.package.name}
        </p>

        <div style={{ fontSize: 15 }}>
          {ReactHtmlParser(props.package.description)}
        </div>
        <div style={{ flex: 1 }} />

        <div>
          <p style={{ marginTop: 20, marginBottom: 12, fontSize: 25 }}>{`$${
            props.package.price
          } / ${props.package.yearly ? "year" : `month`}`}</p>

          <div style={{ fontSize: 15 }}>
            {ReactHtmlParser(props.package.footerText)}
          </div>
          {/* <p
          style={{
            color: "rgba(256, 256, 256, 0.7)",
            fontSize: 15,
            marginBottom: 4,
          }}
        >
          {props.package.yearly ? "(Billed annually)" : "(Billed monthly)"}
        </p> */}
          {didPurchase ? (
            <div />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 30,
              }}
            >
              <SmallDarkCTA
                title="Choose this plan"
                width={240}
                onClick={() => {
                  //@ts-ignore
                  history.push(`/club/${props.package.id}`);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

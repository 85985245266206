import React, { useEffect } from "react";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";
import colors from "../../constants/colors";
import ReactHtmlParser from "react-html-parser";
import MediaHeader from "./components/media-header";
import firebase from "firebase";
import { defaultAnalytics } from "../../firebase/store";

export default function BlogPostPage({ isMobile }) {
  const styles = {
    headerContainer: {
      display: "flex",
      flexDirection: "column" as const,

      alignItems: "center",
      minHeight: "100vh",
    },
    imageRow: {
      marginTop: 30,
      marginBottom: 30,
      display: "flex",
      flexDirection: "row" as const,
      width: isMobile ? "90vw" : "70vw",
      overflowX: "scroll" as const,
    },
    image: {
      flex: 1,
      justifyContent: "center" as const,
      display: "flex",
    },
    largeImage: {
      height: "auto",
      width: isMobile ? "90vw" : "70vw",
    },
    regularImage: {
      height: 350,
      width: "auto",
      marginLeft: 15,
      marginRight: 15,
    },
    brightTextBos: {
      backgroundColor: colors.orange,
      marginTop: 30,
      marginBottom: 30,
      padding: isMobile ? 20 : 80,
    },
  };

  let { id } = useParams<any>();
  const post = useSelector<string, any>((state: any) =>
    state.firestore.data.blogPosts ? state.firestore.data.blogPosts[id] : null
  );
  const firestore = useFirestore();

  useFirestoreConnect([
    {
      collection: "blogPosts",
      doc: id,
    },
  ]);

  useEffect(() => {
    var postRef = firestore.collection("blogPosts").doc(id);

    postRef.update({
      viewCount: firebase.firestore.FieldValue.increment(1),
    });
    defaultAnalytics.logEvent("view_blog_post", {
      postId: id,
      postName: post.title,
    });
  }, []);

  const renderContentBlock = (content: any, index: number) => {
    switch (content.kind) {
      case "largeImage":
      case "imageRow":
        return (
          <div style={styles.imageRow} key={index}>
            {(content.images || []).map((item, index) => (
              <div style={styles.image} key={index}>
                <img
                  src={item.src}
                  style={
                    content.kind === "largeImage"
                      ? styles.largeImage
                      : styles.regularImage
                  }
                />
              </div>
            ))}
          </div>
        );

      case "normalText":
        return (
          <div style={{ marginTop: 30, marginBottom: 30 }} key={index}>
            {ReactHtmlParser(content.text)}
          </div>
        );

      case "textBox":
        return (
          <div key={index} style={styles.brightTextBos}>
            {ReactHtmlParser(content.text)}
          </div>
        );
    }
  };

  return post ? (
    <div style={styles.headerContainer}>
      <MediaHeader post={post} isMobile={isMobile} />
      <div style={{ width: isMobile ? "90vw" : "70vw" }}>
        {(post.content || []).map((item, index) =>
          renderContentBlock(item, index)
        )}
      </div>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        marginTop: 12,
      }}
    >
      <PulseLoader color={colors.orange} loading={true} size={20} margin={3} />
    </div>
  );
}

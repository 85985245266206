import React from "react";
import Select from "react-select";
import colors from "../../constants/colors";

const options = [
  { value: "Tops", label: "Tops" },
  { value: "Bottoms", label: "Bottoms" },
  { value: "Outerwear", label: "Outerwear" },
  { value: "Footwear", label: "Footwear" },
  { value: "Jewelry", label: "Jewelry" },
  { value: "Accessories", label: "Accessories" },
];

export default function CategorySelect({ filters, setFilters }) {
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      width: state.selectProps.width,
      color: state.selectProps.menuColor,
    }),
    control: () => ({
      minWidth: 100,
      display: "flex",
    }),
    indicatorSeparator: () => ({ opacity: 0 }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        paddingTop: 6,
        paddingBottom: 6,
        borderRadius: 8,
        backgroundColor: colors.orange,
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "white",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "white",
    }),
  };

  return (
    <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
      <Select
        isClearable={false}
        isSearchable={false}
        isMulti={true}
        options={options}
        styles={customStyles}
        menuColor={colors.orange}
        onChange={(e) => setFilters(e)}
        value={filters}
      />
    </div>
  );
}
